import { Patology } from './Patology'
import { v4 as uuidv4 } from 'uuid'

export interface PatologyDTO {
  id: string | undefined
  name: string
  cron: string
  priority: number | undefined
  min_steps: number
}

export function emptyPatologyDTO(userID: string) {
  return {
    id: uuidv4(),
    name: '',
    cron: '',
    priority: 0,
    min_steps: 0,
  }
}

export function fromModel(d: Patology): PatologyDTO {
  return {
    id: d.id,
    name: d.name,
    cron: d.cron,
    priority: d.priority,
    min_steps: d.min_steps,
  }
}

export function toModel(d: PatologyDTO): Patology {
  return new Patology(d)
}

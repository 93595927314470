import React from 'react'
import { FormControl } from 'react-bootstrap'
import PerfectScrollbar from 'react-perfect-scrollbar'
import Friends from './Friends'
import styles from '../header/Header.module.css'
const ChatList = ({ listOpen, closed }) => {
  let listClass = ['header-user-list']
  if (listOpen) {
    listClass = [...listClass, 'open']
  }

  return (
    <React.Fragment>
      <div className={listClass.join(' ')}>
        <div>
          <div className={styles.chatHeader}>
            <FormControl
              className={styles.chatInput}
              type="text"
              id="search-friends"
              placeholder="Buscar"
            />
          </div>
        </div>
        <div className="h-list-body">
          <div to="#" className="h-close-text" onClick={closed}>
            <i className="feather icon-chevrons-right" style={{ color: '#A9BA37' }} />
          </div>
          <div className="main-friend-cont scroll-div">
            <div className="main-friend-list" style={{ height: 'calc(100vh - 85px)' }}>
              <PerfectScrollbar>
                <Friends listOpen={listOpen} />
              </PerfectScrollbar>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ChatList

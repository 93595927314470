import React from 'react'
import { RouteProps } from '../../routes/AppRouter'
import { TreatmentsForm as TF } from '../../features/treatments'
import { CurrentNavHeader } from '../../components/current-nav-header/CurrentNavHeader'
import { ROUTE_TREATMENTS } from '../../routes/routes-constants'
import { useTitleHeaderContext } from '../../common/utils/TitleHeaderContext'
import { Box } from '@mui/material'
import logoCareme from '../../assets/caremeLogoMain.svg'
import style from '../layout/Main.module.css'

type TreatmentFormProps = {
  userID?: string
} & RouteProps

export function TreatmentsForm(props: TreatmentFormProps) {
  const title = props.title || ''
  const { setTitle } = useTitleHeaderContext()

  setTitle(title)
  const state: TreatmentFormProps = props.location?.state as TreatmentFormProps

  return (
    <>
      <TF id={props.id === ROUTE_TREATMENTS ? undefined : props.id} userID={state?.userID} />
      <Box display={'flex'} justifyContent={'flex-end'}>
        <img src={logoCareme} alt="Logo Careme" className={style.logo} />
      </Box>
    </>
  )
}

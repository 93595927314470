import { RouteProps } from '../../routes/AppRouter'
import { TemplateForm as TF } from '../../features/form-generator'
import { ROUTE_CREATE } from '../../routes/routes-constants'
import { useTitleHeaderContext } from '../../common/utils/TitleHeaderContext'

export function FormGenerator(props: RouteProps) {
  const title = props.title || ''
  const { setTitle } = useTitleHeaderContext()

  setTitle(title)

  return (
    <>
      <TF id={props.id === ROUTE_CREATE ? undefined : props.id} />
    </>
  )
}

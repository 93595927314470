import PickerToolbar from '@material-ui/pickers/_shared/PickerToolbar'
import ToolbarButton from '@material-ui/pickers/_shared/ToolbarButton'
import styles from './CustomToolbar.module.css'

const CustomToolbar = function (props: any) {
  const { date, isLandscape, openView, setOpenView, title } = props

  const handleChangeViewClick = (view: string) => (e: any) => {
    setOpenView(view)
  }

  return (
    <PickerToolbar className={styles.toolbar} title={title} isLandscape={isLandscape}>
      <ToolbarButton
        className={styles.year}
        onClick={handleChangeViewClick('year')}
        variant="h6"
        label={date.format('YYYY')}
        selected={openView === 'year'}
      />
      <ToolbarButton
        className={styles.date}
        // onClick={handleChangeViewClick('date')}
        variant="h6"
        selected={openView === 'date'}
        label={date.format('DD/MMMM')}
        disabled
      />
      <ToolbarButton
        className={styles.day}
        // onClick={handleChangeViewClick('date')}
        variant="h6"
        selected={openView === 'date'}
        label={date.format('dddd')}
        disabled
      />
    </PickerToolbar>
  )
}

export default CustomToolbar

import React, { useEffect, useState } from 'react'
import { navigate } from '@reach/router'
import { ROUTE_ARTICLES, ROUTE_ARTICLES_FORM } from '../../routes/routes-constants'
import { Article } from '../../modules/content/models/Article'
import { Query } from '../../common/api/Query'
import { Actions, Pager } from '../../components/table/types'
import { getContentContainer } from '../../container/content-module'
import { ArticleService } from '../../modules/content/services/ArticleService'
import { ARTICLE_SERVICE_KEY } from '../../modules/content'
import { AppTable, Field } from '../../components/table'
import { useTranslation } from 'react-i18next'
import seeIcon from '../../assets/articles/see.svg'
import editIcon from '../../assets/articles/edit.svg'
import deleteIcon from '../../assets/articles/delete.svg'
import { getUserContainer } from '../../container/user-module'
import { IUserService, LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from '../../modules/users'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import genericStyle from '../../common/utils/generic.module.css'
import { Box, Modal, Tooltip } from '@mui/material'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import addArticle from '../../assets/articles/addArticle.svg'
import { User } from '../../modules/users/models/User'
import { CustomModal } from '../../components/modal/CustomModal'
import { getAppContainer, STATUS_SERVICE_KEY } from '../../container/app'
import { IStatusService } from '../../common/status/StatusService'
import { useIsOpenMenuLeftContext } from '../../common/utils/isOpenMenuLeftContext'
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material'
import articlesImage from '../../assets/articles/articles.svg'
import { reduceString } from '../../common/utils/strings'
import styles from './View.module.css'
import addPatientIcon from '../../assets/user-table/addPatient.svg'

const userContainer = getUserContainer()
const loggedUserService = userContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
const userService = userContainer.get<IUserService>(USER_SERVICE_KEY)

const contentContainer = getContentContainer()
const articlesService = contentContainer.get<ArticleService>(ARTICLE_SERVICE_KEY)
const statusService = getAppContainer().get<IStatusService>(STATUS_SERVICE_KEY)

export function Table() {
  const { t } = useTranslation()

  const loggedUser = loggedUserService.get()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [articles, setArticles] = useState<Article[]>([])
  const [count, setCount] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const [articlesPerPage, setArticlesPerPage] = useState<number>(10)
  const [pager, setPager] = useState<Pager>()
  const [creators, setCreators] = useState<Map<string, string>>(new Map())
  const [user, setUser] = useState<User>()
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [currentArticle, setCurrentArticle] = useState<Article>()
  const { openMenuLeft } = useIsOpenMenuLeftContext()

  useEffect(() => {
    if (!isLoading) {
      return
    }
    articlesService
      .getFilteredList(
        new Query({
          sort: [{ field: 'createdAt', desc: true }],
          pager: { offset: page * articlesPerPage, limit: articlesPerPage },
        })
      )
      .subscribe((res) => {
        setIsLoading(true)
        setArticles(res.items)
        setCount(res.count)
      })
  }, [isLoading, pager, articlesPerPage])

  useEffect(() => {
    if (!loggedUser?.id) return
    userService.getByID(loggedUser?.id).subscribe((res) => {
      if (!res) return
      setUser(res)
    })
  }, [isLoading, loggedUser])

  useEffect(() => {
    if (!articles.length) {
      return
    }

    const ids = articles.map((u) => u.creatorID)
    userService
      .getFilteredList(
        new Query({
          pager: { offset: 0, limit: ids.length },
          query: [
            {
              name: 'ids',
              value: ids,
            },
          ],
        })
      )
      .subscribe((res) =>
        setCreators(new Map(res.items.map((u) => [u.id, u.firstName] as [string, string])))
      )
  }, [articles])

  useEffect(() => {
    setIsLoading(true)

    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: articlesPerPage,
      handleChangeRowsPerPage,
    })
    //setIsLoading(true)
  }, [page, count, articlesPerPage])

  const handlePaginationChange = (event: unknown, value: number) => {
    setPage(value)
  }

  const handleSee = (article: Article) => navigate(`${ROUTE_ARTICLES}/${article.id}`)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setArticlesPerPage(10)
      return
    }

    setArticlesPerPage(Number.parseInt(event.target.value) || 10)
  }

  const editArticle = (a: Article) => navigate(`${ROUTE_ARTICLES_FORM}/${a.id}`)

  const removeArticle = (a: Article) => {
    setCurrentArticle(a)
    setOpenDeleteModal(true)
  }
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false)
  }
  const handleDeleteArticle = () => {
    if (currentArticle?.id)
      articlesService.delete(currentArticle.id).subscribe((_) => {
        statusService.sendStatus({ variant: 'success' })
        setIsLoading(true)
      })
    setOpenDeleteModal(false)
    setIsLoading(!isLoading)
  }

  const isCreator = (a: Article) => a.creatorID === loggedUser?.id

  const handleAddArticle = () => navigate(ROUTE_ARTICLES_FORM)

  const fields: Field<Article>[] = [
    {
      name: 'title',
      label: t('title'),
    },
    {
      name: 'createdAt',
      label: t('creationDate'),
      renderFunc: (f, i) => new Date(i.createdAt).toLocaleDateString(),
    },
    {
      name: 'creatorID',
      label: t('creator'),
      renderFunc: (f, i) => creators.get(i.creatorID) || '',
    },
  ]

  const actions: Actions<Article> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: handleSee,
        icon: seeIcon,
        label: 'see',
      },
      {
        handler: (a) => editArticle(a),
        icon: editIcon,
        label: 'edit',
        hidden: (a) => !isCreator(a),
      },
      {
        handler: removeArticle,
        icon: deleteIcon,
        label: 'delete',
        hidden: (a) => !isCreator(a),
      },
    ],
  }

  // Divide los artículos en filas
  const rows = []
  for (let i = 0; i < articles.length; i += 4) {
    rows.push(articles.slice(i, i + 4))
  }

  return (
    <>
      <Modal open={openDeleteModal} onClose={handleCloseDeleteModal}>
        <CustomModal
          title={t('deleteArticle')}
          warningText={t('irreversibleArticleAction')}
          handleClose={handleCloseDeleteModal}
          handleSave={handleDeleteArticle}
        />
      </Modal>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          marginLeft: openMenuLeft ? '50px' : '',
        }}
      >
        <Box
          style={{
            maxWidth: openMenuLeft ? 'calc(100% - 250px)' : 'calc(100% - 300px)',
            position: 'relative',
          }}
          className={genericStyle.pageContainer}
        >
          <div className={genericStyle.headerContainer}>
            <Typography>Novedades</Typography>
          </div>
          <Box className={genericStyle.container}>
            <Box
              display={'flex'}
              flexWrap={'wrap'}
              marginLeft={openMenuLeft ? '40px' : ''}
              sx={{ overflow: 'auto' }}
            >
              {articles.map((article) => (
                <Card
                  sx={{
                    width: '310px',
                    height: '143px',
                    margin: '17px',
                    border: '3px solid #ADB84E',
                    borderRadius: '32px',
                  }}
                >
                  <CardActionArea sx={{ display: 'flex', height: '100%' }}>
                    <CardMedia
                      sx={{ width: 'auto', height: '100%' }}
                      component="img"
                      height="137"
                      width="122"
                      image={articlesImage}
                      alt="green iguana"
                    />
                    <CardContent sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                      <Typography
                        sx={{ fontFamily: 'Montserrat-ExtraBold', fontSize: 16 }}
                        gutterBottom
                        variant="h5"
                        component="div"
                      >
                        {reduceString(article.title, 15)}
                      </Typography>
                      <Typography sx={{ minHeight: '58px' }} variant="body2" color="text.secondary">
                        {reduceString(article.content, 30)}
                      </Typography>
                      <Box display={'flex'} justifyContent={'space-evenly'} mt={'4px'}>
                        <img
                          className={styles.actions}
                          style={{ marginRight: 5 }}
                          onClick={() => handleSee(article)}
                          src={seeIcon}
                          alt={'see'}
                        />
                        {isCreator(article) && (
                          <>
                            <img
                              className={styles.actions}
                              style={{ marginRight: 5 }}
                              onClick={() => editArticle(article)}
                              src={editIcon}
                              alt={'edit'}
                            />
                            <img
                              className={styles.actions}
                              onClick={() => removeArticle(article)}
                              src={deleteIcon}
                              alt={'delete'}
                            />
                          </>
                        )}
                      </Box>
                    </CardContent>
                  </CardActionArea>
                </Card>
              ))}
            </Box>
          </Box>
          {user && user.isPatient ? (
            ''
          ) : (
            <Box
              style={{ position: 'absolute', right: -30, bottom: -3, cursor: 'pointer' }}
              onClick={handleAddArticle}
            >
              <Tooltip title={t('addArticle')}>
                <img src={addArticle} alt="add patient" width={90} />
              </Tooltip>
            </Box>
          )}
        </Box>
      </div>
    </>
  )
}

import { Box, IconButton, Typography } from '@mui/material'
import Modal from '@mui/material/Modal'
import React, { useState } from 'react'
import LineChart from '../../components/lineChart/LineChart'
import StackedBarChart from '../../components/stackedBarChart/StackedBarChart'
import { useTranslation } from 'react-i18next'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import TextField from '@mui/material/TextField'
import calendarIcon from '../../assets/calendar/calendar-newEvent.svg'
import style from '../calendar/Editor.module.css'
import close from '../../assets/articles/delete.svg'

interface StatisticsModalProps {
  hookOpen: boolean
  changeState: () => void
  eventProps: {
    titulo: string
    data: any[]
    dataSetsNames: string[]
    tipoChart: string
  }
}

export type DateFilter = {
  startDate: Date
  finishDate: Date
}

const renderChart = (param: string, props: StatisticsModalProps, date: DateFilter) => {
  switch (param) {
    case 'line':
      return (
        <LineChart
          titulo={props.eventProps.titulo}
          data={props.eventProps.data}
          dataSetsNames={props.eventProps.dataSetsNames}
          filterDate={date}
          eje={true}
          graphicsPoints={7}
        />
      )
    case 'stackedBar':
      return (
        <StackedBarChart
          titulo={props.eventProps.titulo}
          data={props.eventProps.data}
          dataSetsNames={props.eventProps.dataSetsNames}
          filterDate={date}
          eje={true}
        />
      )
  }
}

const StatisticsModal = (props: StatisticsModalProps) => {
  // const customThemeCalendar = createTheme({
  //   palette: {
  //     primary: {
  //       main: '#8e9631 !important',
  //       contrastText: '#ffffff !important', // --color-dark-blue
  //     },
  //     secondary: {
  //       main: '#8e9631 !important', // --color-orange
  //       contrastText: '#ffffff !important',
  //     },
  //   },
  //   // components: {
  //   //   MuiBadge: {
  //   //     anchorOriginBottomRightCircle: {
  //   //       transform: 'translateX(-10px) translateY(2px)',
  //   //     },
  //   //   },
  //   // },
  // })

  const { t } = useTranslation()

  const [date, setDate] = useState<DateFilter>({
    startDate: new Date(new Date().setDate(new Date().getDate() - 6)),
    finishDate: new Date(),
  })
  const [DateTimePickerError, setDateTimePickerError] = useState<string>('')

  const handlerChangeFinishDate = (event: any) => {
    if (!event) return

    setDate(Object.assign({ ...date }, { startDate: date.startDate, finishDate: event.toDate() }))
  }

  const handlerChangeStartDate = (event: any) => {
    if (!event) return
    setDate(Object.assign({ ...date }, { startDate: event.toDate(), finishDate: date.finishDate }))
  }
  return (
    <Modal
      open={props.hookOpen}
      onClose={props.changeState}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            borderRadius: '32px',
          }}
        >
          <Box
            sx={{
              width: '50%',
              height: '50%',
              backgroundColor: '#f9f9f9',
              padding: '3%',
              paddingTop: '0px',
              borderRadius: '32px',
            }}
          >
            <IconButton
              sx={{
                marginLeft: '98%',
                marginTop: '3%',
              }}
              onClick={props.changeState}
            >
              <img src={close} alt={'Close icon'} />
            </IconButton>
            <Box style={{ display: 'flex' }}>
              <Box style={{ marginRight: 4 }}>
                <Typography style={{ marginBottom: 0, marginRight: 24 }} className={style.label}>
                  {t('startDate')}
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
                  <DatePicker
                    key={'startDate'}
                    inputFormat={'DD/MM/YYYY'}
                    onChange={(e) => handlerChangeStartDate(e)}
                    value={date.startDate}
                    label={''}
                    onError={(reason) => {
                      switch (reason) {
                        case 'invalidDate':
                          setDateTimePickerError(t('invalidDateMessage'))
                          break
                        case 'minDate':
                          setDateTimePickerError(t('minDateMessage'))
                          break
                      }
                    }}
                    renderInput={(props) => (
                      <TextField
                        id={'startDate'}
                        sx={{
                          '& fieldset': {
                            borderRadius: 32,
                            border: '1px solid #E8E7EC',
                            fontFamily: 'Montserrat',
                          },
                          '& .MuiInputBase-root': { fontFamily: 'Montserrat' },
                          '& .MuiOutlinedInput-root': {
                            border: 'none',
                            '&.Mui-focused fieldset': {
                              borderColor: '#ADB84E',
                            },
                          },
                        }}
                        size={'small'}
                        helperText={props.error && DateTimePickerError}
                        {...props}
                      />
                    )}
                    components={{
                      OpenPickerIcon: () => (
                        <img src={calendarIcon} alt="calendar" style={{ marginRight: 8 }} />
                      ),
                    }}
                  />
                </LocalizationProvider>
              </Box>
              <Box>
                <Typography style={{ marginBottom: 0, marginRight: 24 }} className={style.label}>
                  {t('finishDate')}
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
                  <DatePicker
                    key={'finishDate'}
                    inputFormat={'DD/MM/YYYY'}
                    onChange={(e) => handlerChangeFinishDate(e)}
                    value={date.finishDate}
                    label={''}
                    onError={(reason) => {
                      switch (reason) {
                        case 'invalidDate':
                          setDateTimePickerError(t('invalidDateMessage'))
                          break
                        case 'minDate':
                          setDateTimePickerError(t('minDateMessage'))
                          break
                      }
                    }}
                    renderInput={(props) => (
                      <TextField
                        id={'finishDate'}
                        sx={{
                          '& fieldset': {
                            borderRadius: 32,
                            border: '1px solid #E8E7EC',
                            fontFamily: 'Montserrat',
                          },
                          '& .MuiInputBase-root': { fontFamily: 'Montserrat' },
                          '& .MuiOutlinedInput-root': {
                            border: 'none',
                            '&.Mui-focused fieldset': {
                              borderColor: '#ADB84E',
                            },
                          },
                        }}
                        size={'small'}
                        helperText={props.error && DateTimePickerError}
                        {...props}
                      />
                    )}
                    components={{
                      OpenPickerIcon: () => (
                        <img src={calendarIcon} alt="calendar" style={{ marginRight: 8 }} />
                      ),
                    }}
                  />
                </LocalizationProvider>
              </Box>
            </Box>
            {renderChart(props.eventProps.tipoChart, props, date)}
          </Box>
        </Box>
      </>
    </Modal>
  )
}

export default StatisticsModal

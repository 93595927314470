import React, { useEffect, useState } from 'react'
import { RouteComponentProps, Router } from '@reach/router'
import { NewsWall } from '../pages/newsWall'
import { Permission } from '../common/permission'
import {
  ROUTE_ARTICLE,
  ROUTE_ARTICLES,
  ROUTE_ARTICLES_FORM,
  ROUTE_ARTICLES_FORM_EDIT,
  ROUTE_CALENDAR,
  ROUTE_CALENDAR_FORM,
  ROUTE_CALENDAR_FORM_EDIT,
  ROUTE_CALENDAR_ID,
  ROUTE_CONFIGURATION,
  ROUTE_DASHBOARD_ID,
  ROUTE_DASHBOARD_TRAINING_ID,
  ROUTE_FORM_GENERATOR,
  ROUTE_FORM_GENERATOR_ASSIGNTO,
  ROUTE_FORM_GENERATOR_FORM_CREATE,
  ROUTE_FORM_GENERATOR_FORM_EDIT,
  ROUTE_FORM_GENERATOR_FORM_RESULTS,
  ROUTE_FORM_GENERATOR_USERS,
  ROUTE_MESSENGER,
  ROUTE_MESSENGER_ID,
  ROUTE_MOBILE_MENU,
  ROUTE_NEWS_WALL,
  ROUTE_NOTIFICATIONS_CONFIG,
  ROUTE_PATIENT_FORMS,
  ROUTE_PATIENT_FORMS_VIEW,
  ROUTE_PATIENTS,
  ROUTE_RESOURCES,
  ROUTE_RESOURCES_FORM_EDIT,
  ROUTE_RESOURCES_ID,
  ROUTE_RESOURCES_SHARED_WITH_ME,
  ROUTE_RESOURCES_SHARED_WITH_ME_ID,
  ROUTE_ROLE_PERMISSIONS,
  ROUTE_STATISTICS,
  ROUTE_STATISTICS_ID,
  ROUTE_TRAINING,
  ROUTE_TRAINING_ID,
  ROUTE_TRAININGS,
  ROUTE_TRAININGS_CREATE,
  ROUTE_TRAININGS_ID,
  ROUTE_TREATMENTS,
  ROUTE_TREATMENTS_ID,
  ROUTE_USER_FORMS,
  ROUTE_USER_PROFILE,
  ROUTE_USERS_CREATE,
  ROUTE_USERS_CREATE_ID,
  ROUTE_PRIVACY_POLICY,
  ROUTE_COOKIES_POLICY,
  ROUTE_CALENDAR_EXERCISES,
  ROUTE_CALENDAR_EXERCISES_ID,
} from './routes-constants'
import { Messenger } from '../pages/messenger'
import { Calendar } from '../pages/calendar'
import { ArticlesForm } from '../pages/articles-form'
import { ArticlesView } from '../pages/articles-view'
import { FormGenerator } from '../pages/form-generator'
import { FormGeneratorTable } from '../pages/form-generator-table'
import {
  ARTICLES_TITLE,
  CALENDAR_EXERCISE_TITLE,
  CALENDAR_TITLE,
  CONFIGURATION_TITLE,
  COOKIES_POLICY_TITLE,
  CREATE_PATIENT_TITLE,
  CREATE_TRAINING_TITLE,
  DASHBOARD_TITLE,
  EDIT_PATIENT_TITLE,
  FORM_GENERATOR_ASSIGNTO_TITLE,
  FORM_GENERATOR_EDITOR_TITLE,
  FORM_GENERATOR_FORM_CREATE_TITLE,
  FORM_GENERATOR_RESULTS_TITLE,
  FORM_GENERATOR_TITLE,
  FORM_GENERATOR_USERS_TITLE,
  FORMS_TITLE,
  MESSENGER_TITLE,
  MOBILE_MENU_TITLE,
  NEWSWALL_TITLE,
  PATIENT_FORM_VIEW_TITLE,
  PATIENTS_FORMS_TITLE,
  PRIVACY_POLICY_TITLE,
  RESOURCES_NOTIFICATIONS_CONFIG_TITLE,
  RESOURCES_SHARED_WITH_ME_TITLE,
  RESOURCES_TITLE,
  ROLE_PERMISSIONS_TITLE,
  STATISTICS_TITLE,
  TRAINING_TITLE,
  TREATMENT_TITLE,
  USER_PROFILE_TITLE,
  USERS_TITLE,
} from './title-constants'
import { FormGeneratorResults } from '../pages/form-generator-results'
import { Configuration } from '../pages/configuration'
import { CalendarForm } from '../pages/calendar-form'
import { PatientFormsTable } from '../pages/patient-forms-list'
import { PatientFormView } from '../pages/patient-forms-view'
import { Resources } from '../pages/resources'
import { ResourcesForm } from '../pages/resources-form'
import { NotificationsConfig } from '../pages/notifications-config/NotificationsConfig'
import { RolePermissions } from '../pages/role-permissions'
import { ICircleService } from '../modules/users/services/CircleService'
import { User } from '../modules/users/models/User'
import { EditParticipant } from '../pages/users'
import { FormGeneratorUsers } from '../pages/form-generator-users'
import { UserProfile } from '../pages/user-profile'
import { MobileMenu } from '../pages/mobile-menu'
import { getUserContainer } from '../container/user-module'
import { CIRCLE_SERVICE_KEY, LOGGED_USER_SERVICE_KEY } from '../modules/users/container'
import { LoggedUserService } from '../modules/users/services/LoggedUserService'
import { ArticlesTable } from '../pages/articles-table'
import { UsersTable } from '../pages/users-table'
import { Statistics } from '../pages/statistics'
import { Training } from '../pages/training'
import { TrainingView } from '../pages/training-view'
import { Dashboard } from '../pages/dashboard'
import { TreatmentsForm } from '../pages/treatments-form'
import { AllTrainings } from '../pages/trainings'
import { TrainingsView } from '../pages/trainings-view/TrainingsView'
import { CreateTraining } from '../pages/training-form'
import { FormGeneratorAssignTo } from 'pages/form-generator-assignTo'
import { PrivacyPolicy } from '../pages/privacy-policy'
import { CookiesPolicy } from '../pages/cookies-policy'
import { CalendarExercise } from '../pages/calendar-exercise'
import { Box } from '@mui/material'

export type RouteProps = {
  title?: string
  subtitle?: string
  id?: string
} & RouteComponentProps

const circleService = getUserContainer().get<ICircleService>(CIRCLE_SERVICE_KEY)
const userContainer = getUserContainer()
const loggedUserService = userContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)

export const AppRouter: React.FC = () => {
  const [circle, setCircle] = useState<User | undefined>(circleService.getActive())

  useEffect(() => {
    circleService.getActiveObservable().subscribe((c) => setCircle(c))
  }, [circle])

  const generalRoutes = (
    <>
      {/* {loggedUserService.userCan(Permission.createFormTemplates) && (*/}
      <FormGenerator path={ROUTE_FORM_GENERATOR_FORM_EDIT} title={FORM_GENERATOR_EDITOR_TITLE} />
      {/*)}*/}

      <FormGeneratorResults
        path={ROUTE_FORM_GENERATOR_FORM_RESULTS}
        title={FORM_GENERATOR_RESULTS_TITLE}
      />
      {loggedUserService.userCan(Permission.createFormTemplates) && (
        <FormGeneratorTable path={ROUTE_FORM_GENERATOR} title={FORM_GENERATOR_TITLE} />
      )}

      <FormGeneratorUsers path={ROUTE_FORM_GENERATOR_USERS} title={FORM_GENERATOR_USERS_TITLE} />

      <ArticlesView path={ROUTE_ARTICLE} />
      <ArticlesForm path={ROUTE_ARTICLES_FORM_EDIT} title={ARTICLES_TITLE} />
      <ArticlesForm path={ROUTE_ARTICLES_FORM} title={ARTICLES_TITLE} />

      <ArticlesForm path={ROUTE_ARTICLES_FORM_EDIT} title={ARTICLES_TITLE} />

      <ArticlesTable path={ROUTE_ARTICLES} title={ARTICLES_TITLE} />

      <UsersTable path={ROUTE_PATIENTS} title={USERS_TITLE} />

      <Configuration path={ROUTE_CONFIGURATION} title={CONFIGURATION_TITLE} />

      {loggedUserService.userCan(Permission.editViewRolePermissions) && (
        <RolePermissions path={ROUTE_ROLE_PERMISSIONS} title={ROLE_PERMISSIONS_TITLE} />
      )}

      <MobileMenu path={ROUTE_MOBILE_MENU} title={MOBILE_MENU_TITLE} />
      <EditParticipant path={ROUTE_USERS_CREATE} title={CREATE_PATIENT_TITLE} />
      <EditParticipant path={ROUTE_USERS_CREATE_ID} title={EDIT_PATIENT_TITLE} />
      <UserProfile path={ROUTE_USER_PROFILE} title={USER_PROFILE_TITLE} />
      <NewsWall path={ROUTE_NEWS_WALL} title={NEWSWALL_TITLE} />
      <Messenger path={ROUTE_MESSENGER} title={MESSENGER_TITLE} />
      <Messenger path={ROUTE_MESSENGER_ID} title={MESSENGER_TITLE} />
      <Calendar path={ROUTE_CALENDAR} title={CALENDAR_TITLE} />
      <Calendar path={ROUTE_CALENDAR_ID} title={CALENDAR_TITLE} />
      <CalendarExercise path={ROUTE_CALENDAR_EXERCISES} title={CALENDAR_EXERCISE_TITLE} />
      <CalendarExercise path={ROUTE_CALENDAR_EXERCISES_ID} title={CALENDAR_EXERCISE_TITLE} />
      <CalendarForm path={ROUTE_CALENDAR_FORM_EDIT} title={CALENDAR_TITLE} />

      <PatientFormView path={ROUTE_PATIENT_FORMS_VIEW} title={PATIENT_FORM_VIEW_TITLE} />
      <PatientFormsTable path={ROUTE_PATIENT_FORMS} title={PATIENTS_FORMS_TITLE} />
      <Resources path={ROUTE_RESOURCES} title={RESOURCES_TITLE} />
      <Resources path={ROUTE_RESOURCES_ID} title={RESOURCES_TITLE} />
      <Resources path={ROUTE_RESOURCES_SHARED_WITH_ME} title={RESOURCES_SHARED_WITH_ME_TITLE} />
      <Resources path={ROUTE_RESOURCES_SHARED_WITH_ME_ID} title={RESOURCES_SHARED_WITH_ME_TITLE} />
      <ResourcesForm path={ROUTE_RESOURCES_FORM_EDIT} title={RESOURCES_TITLE} />

      {loggedUserService.userCan(Permission.configureNotifications) && (
        <NotificationsConfig
          path={ROUTE_NOTIFICATIONS_CONFIG}
          title={RESOURCES_NOTIFICATIONS_CONFIG_TITLE}
        />
      )}
      <Statistics path={ROUTE_STATISTICS} title={STATISTICS_TITLE} />
      <Statistics path={ROUTE_STATISTICS_ID} title={STATISTICS_TITLE} />
      <Training path={ROUTE_TRAINING} title={TRAINING_TITLE} />
      <Training path={ROUTE_DASHBOARD_TRAINING_ID} title={TRAINING_TITLE} />
      <TrainingView path={ROUTE_TRAINING_ID} />
      <TrainingsView path={ROUTE_TRAININGS_ID} />
      <AllTrainings path={ROUTE_TRAININGS} title={TRAINING_TITLE} />
      <CreateTraining path={ROUTE_TRAININGS_CREATE} title={CREATE_TRAINING_TITLE} />
      <Dashboard path={ROUTE_DASHBOARD_ID} title={DASHBOARD_TITLE} />
      <TreatmentsForm path={ROUTE_TREATMENTS} title={TREATMENT_TITLE} />
      <TreatmentsForm path={ROUTE_TREATMENTS_ID} title={TREATMENT_TITLE} />
      <FormGeneratorTable path={ROUTE_FORM_GENERATOR} title={FORM_GENERATOR_TITLE} />
      <FormGenerator
        path={ROUTE_FORM_GENERATOR_FORM_CREATE}
        title={FORM_GENERATOR_FORM_CREATE_TITLE}
      ></FormGenerator>
      <FormGeneratorAssignTo
        path={ROUTE_FORM_GENERATOR_ASSIGNTO}
        title={FORM_GENERATOR_ASSIGNTO_TITLE}
      ></FormGeneratorAssignTo>
      <PatientFormsTable path={ROUTE_USER_FORMS} title={FORMS_TITLE}></PatientFormsTable>
      <PrivacyPolicy path={ROUTE_PRIVACY_POLICY} title={PRIVACY_POLICY_TITLE} />
      <CookiesPolicy path={ROUTE_COOKIES_POLICY} title={COOKIES_POLICY_TITLE} />
    </>
  )

  return <Router component="div">{generalRoutes}</Router>
}

import { RouteProps } from '../../routes/AppRouter'
import { CurrentNavHeader } from '../../components/current-nav-header/CurrentNavHeader'
import React from 'react'
import { ResourcesForm as RF } from '../../features/resource'
import { ROUTE_CREATE } from '../../routes/routes-constants'
import { useTitleHeaderContext } from '../../common/utils/TitleHeaderContext'
import { Box } from '@mui/material'
import logoCareme from '../../assets/caremeLogoMain.svg'
import style from '../layout/Main.module.css'

type ResourcesFormProps = {} & RouteProps

export function ResourcesForm(props: ResourcesFormProps) {
  const title = props.title || ''
  const { setTitle } = useTitleHeaderContext()

  setTitle(title)

  return (
    <>
      <RF id={props.id === ROUTE_CREATE ? undefined : props.id} />
      <Box display={'flex'} justifyContent={'flex-end'}>
        <img src={logoCareme} alt="Logo Careme" className={style.logo} />
      </Box>
    </>
  )
}

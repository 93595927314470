import { getNotificationContainer } from '../../container/notification-module'
import { NotificationService } from '../../modules/notifications/services/NotificationService'
import { MESSAGES_SERVICE_KEY, NOTIFICATION_SERVICE_KEY } from '../../modules/notifications'
import { MessageService } from '../../modules/notifications/services/MessageService'
import { getMessengerContainer } from '../../container/messenger-module'
import { ConversationService } from '../../modules/messenger/services/ConversationService'
import { CONVERSATION_SERVICE_KEY } from '../../modules/messenger'
import { getUserContainer } from '../../container/user-module'
import { IUserService, LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from '../../modules/users'
import { ILoggedUserService } from '../../modules/users/services/LoggedUserService'
import { getFormContainer } from '../../container/form-module'
import { UserFormService } from '../../modules/forms/services/UserFormService'
import { USER_FORM_SERVICE_KEY } from '../../modules/forms'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import { Notification as N } from '../../modules/notifications/models/Notification'
import { Message } from '../../modules/notifications/models/Message'
import styles from './List.module.css'
import { ActivityWidget } from './ActivityWidget'
import { Query, QueryParam } from '../../common/api/Query'
import { Conversation, ConversationQuery } from '../../modules/messenger/models/Conversation'
import { TransportType } from '../../common/enums/TransportType'
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@material-ui/lab'
import { forkJoin, Observable } from 'rxjs'
import { emptyUserDTO, User, UserDTO } from '../../modules/users/models/User'
import { WeekViewHome } from './WeekViewHome'
import { Box } from '@mui/material'
import { height } from '@mui/system'

const notificationContainer = getNotificationContainer()
const notificationService = notificationContainer.get<NotificationService>(NOTIFICATION_SERVICE_KEY)
const messageService = notificationContainer.get<MessageService>(MESSAGES_SERVICE_KEY)
const messengerContainer = getMessengerContainer()
const conversationsService = messengerContainer.get<ConversationService>(CONVERSATION_SERVICE_KEY)
const userContainer = getUserContainer()
const userService = userContainer.get<IUserService>(USER_SERVICE_KEY)
const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)

const notificationsPerPage = 7

type NewsWallProps = {
  id?: string
  selectedDate?: Date
}

export const NewsWall = (props: NewsWallProps) => {
  const { t } = useTranslation()
  const loggedUser = loggedUserService.get()

  const [conversationCollection, setConversationCollection] = useState<Conversation[]>([])
  const [notifications, setNotifications] = useState<N[]>([])
  const [notificationMessages, setNotificationMessages] = useState<Map<string, Message>>(new Map())
  const [authors, setAuthors] = useState<Map<string, string>>(new Map())
  const [isLoading, setIsLoading] = useState<boolean>(true) // para observar animacion de recarga hay que poner true
  const [page, setPage] = useState<number>(1)
  const [user, setUser] = useState<UserDTO>(emptyUserDTO())
  const [doctor, setDoctor] = useState<UserDTO>(emptyUserDTO())

  useEffect(() => {
    if (isLoading && loggedUser?.id) {
      conversationsService
        .getFilteredItems(
          new Query({
            pager: { offset: (page - 1) * notificationsPerPage, limit: notificationsPerPage },
            query: [new QueryParam<ConversationQuery>('userID10R2', loggedUser.id)],
          })
        )
        .subscribe((res) => {
          setConversationCollection(res)
        })
      notificationService
        .getFilteredList(
          new Query({
            pager: { offset: (page - 1) * notificationsPerPage, limit: notificationsPerPage },
            query: [
              new QueryParam<N>('recipientID', loggedUser?.id || ''),
              new QueryParam<N>('transportType', TransportType.App),
            ],
            sort: [{ field: 'createdAt', desc: true }],
          })
        )
        .subscribe((res) => {
          //setNotifications(res.items.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime()))
          /* const notificationMessagesTmp = new Map()

          getMessages(res.items.map((n) => n.message)).subscribe((ml) => {
            ml.forEach((m, i) => {
              notificationMessagesTmp.set(res.items[i].id, m)
            })
            console.log('notificationsMessages', notificationMessagesTmp)
            setNotificationMessages(new Map(notificationMessagesTmp))
          })*/
          setIsLoading(false)
        })
    }
  }, [isLoading])

  useEffect(() => {
    if (!notifications.length) {
      return
    }
    const authorsTmp = new Map()
    getUsers(notifications.map((n) => n.senderID)).subscribe((res) => {
      res.filter((u) => u).map((u) => authorsTmp.set(u.id, u.firstName))
      setAuthors(authorsTmp)
    })
  }, [notifications])

  useEffect(() => {
    if (!loggedUser) return
    userService.getByID(loggedUser?.id).subscribe((res) => {
      if (!res) return
      setUser(res)
    })
  }, [loggedUser])

  useEffect(() => {
    if (!user) return
    userService.getByID(user.assignedID).subscribe((res) => {
      if (!res) return
      setDoctor(res)
    })
  }, [user])

  const getUsers = (ids: string[]): Observable<User[]> =>
    forkJoin(ids.map((id) => userService.getByID(id))) as unknown as Observable<User[]>

  const getMessages = (ids: string[]): Observable<Message[]> =>
    forkJoin(ids.map((id) => messageService.getByID(id))) as unknown as Observable<Message[]>

  const mappedNotifications = () => {
    return notifications.map((n) => {
      /*  let message
      let status: ReadingStatus = 0
      if (!n.id) {
        return
      }
      message = notificationMessages.get(n.id)
      console.log('message', message)
      if (message && message.sourceType === SourceType.FormsNewUserForm) {
        userFormService.getByID(message.url).subscribe((uf) => {
          if (uf) {
            status = uf.readingStatus
          }
        })
      }

      if (!message || message.sourceType == SourceType.MessengerMessageCreated) {
        console.log('entro al if de sourceType')
        return
      }

*/

      return (
        <TimelineItem key={n.id}>
          <TimelineOppositeContent style={{ padding: 0 }} />
          <TimelineSeparator>
            <TimelineConnector style={{ backgroundColor: '#e5e5e5' }} />
            <TimelineDot
              variant={'outlined'}
              className={styles.timelineDot}
              style={{ borderColor: '#a9ba37' }}
            />
            <TimelineConnector style={{ backgroundColor: '#e5e5e5' }} />
          </TimelineSeparator>
          <TimelineContent>
            <ActivityWidget
              id={n.id}
              source={n.notType}
              title={n.title}
              description={n.message}
              creator={authors.get(n.senderID)}
              date={n.createdAt}
              url={''}
              status={n.sendingStatus}
            />
          </TimelineContent>
        </TimelineItem>
      )
    })
  }

  return (
    <>
      {/*{!isLoading ? (
        <Box className={styles.wallContainer}>
          <Box display={'flex'} flexDirection={'column'} className={styles.chatWidgetContainer}>
            <Header label={t('chats')} icon={chatIcon} />
            <Box>
              {conversationCollection.length ? (
                <>
                  {conversationCollection.map((c) => (
                    <ChatWidget
                      key={c.id}
                      id={c.id}
                      title={user.isPatient ? doctor.firstName : c.name}
                      avatarUrl={'AvatarUrl'}
                      date={
                        c.messages.length ? c.messages[c.messages.length - 1].createdAt : undefined
                      }
                      lastMessage={
                        c.messages.length ? c.messages[c.messages.length - 1].text : undefined
                      }
                    />
                  ))}
                </>
              ) : (
                <h2 className={styles.textBox}>{t('noChats')}</h2>
              )}
            </Box>
            <Box>
              <Link className={styles.containerFooter} to={ROUTE_MESSENGER} id={ROUTE_MESSENGER}>
                <Box display={'flex'} flexDirection={'row'} className={styles.containerFooter}>
                  <h3>{t('goToChats')}</h3>
                  <img src={rightArrow} alt={rightArrow} className={styles.arrowRight} />
                </Box>
              </Link>
            </Box>
          </Box>
          <Box display={'flex'} flexDirection={'column'} className={styles.notificationContainer}>
            <Header label={t('activity')} />
            <Box>
              {notifications.length ? (
                <Timeline>{mappedNotifications()}</Timeline>
              ) : (
                <h2 className={styles.textBox}>{t('noNotifications')}</h2>
              )}
            </Box>
          </Box>
        </Box>
      ) : (
        ''
      )}*/}
      <Box mt={1}>
        <WeekViewHome
          id={'1'}
          selectedDate={props.selectedDate ?? new Date()}
          handlerEdit={() => {}}
          handlerRemove={() => {}}
        />
      </Box>
    </>
  )
}

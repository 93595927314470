import { Container, ContainerItem } from '../common/container/Container'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import { PatologyApi } from 'modules/patologies/api/PatologyApi'
import { PatologyService } from '../modules/patologies/services/PatologyService'
import { PATOLOGY_SERVICE_KEY, PATOLOGY_MODULE, PATOLOGY_API_KEY } from '../modules/patologies'
import { ContentProps } from 'modules/training/container'

let container: Container

function init(p: ContentProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(PATOLOGY_API_KEY, new ContainerItem({ instance: new PatologyApi() }))

  items.set(
    PATOLOGY_SERVICE_KEY,
    new ContainerItem({ instance: new PatologyService({ apiKey: PATOLOGY_API_KEY }) })
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getPatologyContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[PATOLOGY_MODULE],
      },
    })
  }

  return container
}

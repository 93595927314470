import * as React from 'react'
import { styled } from '@mui/material/styles'
import MuiDrawer from '@mui/material/Drawer'
import { Toolbar, IconButton, Typography, Box } from '@mui/material'
import ListItems from './ListItems'
import styles from '../header/Header.module.css'
import logo from '../../assets/left_menu/logo-menu-expandido.svg'
import collapseIcon from '../../assets/left_menu/icono-colapsar-menu.svg'
import style from '../header/Header.module.css'
import { useTranslation } from 'react-i18next'
import { ROUTE_COOKIES_POLICY, ROUTE_PRIVACY_POLICY } from '../../routes/routes-constants'
import { navigate } from '@reach/router'

type CustomDrawerProps = {
  open: boolean
  toggleDrawer: () => void
  handleChangeView: (view: string) => void
  setOpen: (b: boolean) => void
}

const drawerWidth: number = 310

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  ...(window.innerWidth > 900 && {
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      backgroundColor: '#474747',
      padding: '18px',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: 0,
        padding: '8px 0px 0px 0px',
        [theme.breakpoints.up('sm')]: {
          width: 0,
        },
      }),
    },
  }),
  ...(window.innerWidth < 900 && {
    '& .MuiDrawer-paper': {
      display: 'flex',
      justifyContent: 'space-around',
      whiteSpace: 'nowrap',
      backgroundColor: '#474747',
      padding: '8px 8px 0px 18px',
      height: 220,
      width: '100%',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: 0,
        padding: '8px 0px 0px 0px',
        [theme.breakpoints.up('sm')]: {
          width: 0,
        },
      }),
    },
  }),
}))

const CustomDrawer = (props: CustomDrawerProps) => {
  const { t } = useTranslation()
  const handleClick = (type: string) => {
    props.handleChangeView(type)
  }

  return (
    <>
      <Drawer variant="permanent" open={props.open}>
        <ListItems handleClick={handleClick} isOpen={props.open} setOpen={props.setOpen} />
        {props.open && (
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
          >
            <Typography
              onClick={() => navigate(ROUTE_PRIVACY_POLICY)}
              style={{
                color: '#dcdbdb',
                textAlign: 'center',
                marginRight: 10,
                fontFamily: 'Montserrat-regular',
                fontSize: 14,
              }}
            >
              {t('privacy')}
            </Typography>
            <Typography
              onClick={() => navigate(ROUTE_COOKIES_POLICY)}
              style={{
                color: '#dcdbdb',
                textAlign: 'center',
                marginRight: 10,
                fontFamily: 'Montserrat-regular',
                fontSize: 14,
              }}
            >
              {t('cookies')}
            </Typography>
          </Box>
        )}
      </Drawer>
    </>
  )
}

export default CustomDrawer

import { Treatment } from './Treatment'
import { v4 as uuidv4 } from 'uuid'

export interface TreatmentDTO {
  id: string | undefined
  name: string
  startDate: Date
  endDate: Date | undefined
  duration: number
  frequency: number
  dose: string
  reason: string | undefined
  creatorID: string
  userID: string
}

export function emptyTreatmentDTO(userID: string) {
  return {
    id: uuidv4(),
    name: '',
    startDate: new Date(),
    endDate: new Date(),
    duration: 0,
    frequency: 0,
    dose: '',
    reason: '',
    creatorID: '',
    userID,
  }
}

export function fromModel(d: Treatment): TreatmentDTO {
  return {
    id: d.id,
    name: d.name,
    startDate: d.startDate,
    endDate: d.endDate,
    duration: d.duration,
    frequency: d.frequency,
    dose: d.dose,
    reason: d.reason,
    creatorID: d.creatorID,
    userID: d.userID,
  }
}

export function toModel(d: TreatmentDTO): Treatment {
  return new Treatment(d)
}

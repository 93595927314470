import {
  Box,
  ListItem,
  MenuItem,
  Modal,
} from '@mui/material'

import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { useTranslation } from 'react-i18next'
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import styles from './Editor.module.css'
import { Typography } from '@mui/material'
import { Alert } from '@material-ui/lab'
import { getAppContainer, STATUS_SERVICE_KEY } from '../../container/app'
import { IStatusService } from '../../common/status/StatusService'
import { Query, QueryParam } from '../../common/api/Query'
import { getTrainingContainer } from '../../container/training-module'
import {
  ITRainingService,
  IUserTrainingService,
  TRAINING_SERVICE_KEY,
  USER_TRAINING_SERVICE_KEY,
} from '../../modules/training'
import { TrainingDTO } from '../../modules/training/models/TrainingDTO'
import { fromModel as fromModelUserTraining } from '../../modules/training/models/UserTrainingDTO'
import stylesCommon from '../../components/modal/CustomModal.module.css'
import { Cron, cronString } from '../../modules/forms/enums/Cron'
import {
  emptyUserTrainingDTO,
  UserTrainingDTO,
} from '../../modules/training/models/UserTrainingDTO'
import { UserTraining } from '../../modules/training/models/UserTraining'
import style from './Editor.module.css'
import { v4 as uuidv4 } from 'uuid'
import { Input } from '../../pages/user-profile/userProfileStylesMui'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { TextField } from '@mui/material'
import calendarIcon from '../../assets/calendar/calendar-newEvent.svg'

const statusService = getAppContainer().get<IStatusService>(STATUS_SERVICE_KEY)
const userTrainingService =
  getTrainingContainer().get<IUserTrainingService>(USER_TRAINING_SERVICE_KEY)
const trainingService = getTrainingContainer().get<ITRainingService>(TRAINING_SERVICE_KEY)

// const themeStyles = makeStyles(() => ({
//   input: {
//     '&.Mui-focused': {
//       '& fieldset.MuiOutlinedInput-notchedOutline': {
//         borderColor: '#a9ba37 !important',
//         fontFamily: 'Montserrat-Regular, sans-serif',
//       },
//     },
//   },
//   inputUnderline: {
//     '&:after': {
//       borderColor: '#a9ba37 !important',
//       fontFamily: 'Montserrat-Regular, sans-serif',
//     },
//   },
//   selectInput: {
//     '& ': {
//       width: '29rem !important',
//       fontFamily: 'Montserrat-SemiBold, sans-serif',
//     },

//     '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
//       borderColor: '#a9ba37 !important',
//       fontFamily: 'Montserrat-Regular, sans-serif',
//     },
//   },
//   label: {
//     fontFamily: 'Montserrat-Regular, sans-serif',
//     [`&.${inputLabelClasses.shrink}`]: {
//       color: '#a9ba37',
//       marginTop: '-1%',
//       backgroundColor: 'white',
//       borderColor: '#a9ba37',
//       fontFamily: 'Montserrat-Regular, sans-serif',
//     },
//   },
// }))

// const customThemeCalendar = createTheme({
//   palette: {
//     primary: {
//       main: '#8e9631 !important',
//       contrastText: '#ffffff !important', // --color-dark-blue
//     },
//     secondary: {
//       main: '#8e9631 !important', // --color-orange
//       contrastText: '#ffffff !important',
//     },
//   },
//   // components: {
//   //   MuiBadge: {
//   //     anchorOriginBottomRightCircle: {
//   //       transform: 'translateX(-10px) translateY(2px)',
//   //     },
//   //   },
//   // },
// })

type AssignTrainingFormProps = {
  id?: string
  trainingID?: string
  open: boolean
  handleClose: () => void
}

export function AssignTrainingForm(props: AssignTrainingFormProps) {
  const { t } = useTranslation()
  const cronTypes = cronString()
  const [training, setTraining] = useState<TrainingDTO[]>([])
  const [message, setMessage] = useState<string>()
  const [cron, setCron] = useState<string>(cronTypes[Cron.Empty])
  const [userTraining, setUserTraining] = useState<UserTrainingDTO>(emptyUserTrainingDTO())
  const [userTrainingArray, setUserTrainingArray] = useState<UserTrainingDTO[]>()
  const [selected, setSelected] = useState<string>()
  const [numberOfRepeating, setNumberOfRepeating] = useState<number>(0)
  const [typeOfRepeating, setTypeOfRepeating] = useState<string>('')
  const [startDate, setStartDate] = useState<Date>(new Date())
  const [finishDate, setFinishDate] = useState<Date>(new Date())
  const [repeating, setRepeating] = useState<string>('')
  const [currentTraining, setCurrentTraining] = useState<TrainingDTO>()
  const [translateTrainings, settranslateTrainings] = useState(new Map<string, string>())
  const [DateTimePickerError, setDateTimePickerError] = useState<string>('')

  const handleChange = (s: string) => setCron(s)

  useEffect(() => {
    trainingService
      .getFilteredList(
        new Query({
          query: [],
        })
      )
      .subscribe((res) => {
        let trans = new Map<string, string>()
        if (!res) return
        for (let i = 0; i < res.count; i++) {
          trans.set(t(res.items[i].title), res.items[i].title)
        }
        setTraining(res.items)
        settranslateTrainings(trans)
      })
  }, [])

  useEffect(() => {
    if (!props.id) return
    userTrainingService
      .getFilteredList(
        new Query({
          query: [new QueryParam<UserTraining>('userID', props.id)],
        })
      )
      .subscribe((res) => {
        setUserTrainingArray(res.items)
      })
  }, [props.id])

  useEffect(() => {
    if (!props.trainingID) return
    userTrainingService.getByID(props.trainingID).subscribe((res) => {
      if (!res) return
      setUserTraining(fromModelUserTraining(res))
      /*  const index = res.repeating.lastIndexOf(' ')
      setTypeOfRepeating(res.repeating.substring(index + 1, res.repeating.length))
      setNumberOfRepeating(Number(res.repeating.substring(0, 1)))*/
    })
  }, [props.trainingID])

  useEffect(() => {
    if (!props.trainingID) return
    trainingService.getByID(userTraining.trainingID).subscribe((res) => {
      if (!res) return
      setCurrentTraining(res)
    })
  }, [userTraining])

  const handleBack = () => props.handleClose()

  const handleTrainingSelected = (e: ChangeEvent<{ name?: string; value: any }>) => {
    console.log('el nombre es : ' + e.target.name + 'y el valor ' + e.target.value)
    setCurrentTraining(Object.assign({ title: e.target.value }))
    setSelected(e.target.value)
  }

  function handleStartDateChange(event: any) {
    if (event) {
      setUserTraining(Object.assign({ ...userTraining }, { startDate: event.toDate() }))
      setStartDate(event.toDate())
    }
  }

  function handleFinishDateChange(event: any) {
    if (event) {
      setUserTraining(Object.assign({ ...userTraining }, { finishDate: event.toDate() }))
      setFinishDate(event.toDate())
    }
  }

  const handleSave = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!currentTraining?.title) {
      statusService.sendStatus({ variant: 'warning', message: t('trainingNotSelected') })
      return
    }
    if (numberOfRepeating == 0) {
      statusService.sendStatus({ variant: 'warning', message: t('repetitionsZero') })
      return
    }

    const number = numberOfRepeating.toString()
    const word = numberOfRepeating > 1 ? 'veces' : 'vez'
    const word2 = typeOfRepeating === t('week') ? 'a la' : 'al'
    const type = typeOfRepeating

    const phrase = `${number} ${word} ${word2} ${type}`
    let auxID = ''

    training.map((item) => {
      if (selected === item.title) {
        auxID = item.id
      }
    })

    let newTrainingAssign = Object.assign(
      { ...userTraining },
      {
        id: props.trainingID ? props.trainingID : uuidv4(),
        startDate: userTraining.startDate,
        finishDate: userTraining.finishDate,
        minReps: numberOfRepeating,
        maxReps: numberOfRepeating,
        trainingID: props.trainingID ? userTraining.trainingID : auxID,
        userID: props.id,
        completed: false,
      }
    )

    if (props.trainingID) {
      userTrainingService.update(newTrainingAssign).subscribe((res) => {
        if (res) {
          handleBack()
        }
      })
    } else {
      userTrainingService.add(newTrainingAssign).subscribe((res) => {
        if (res) {
          handleBack()
        }
      })
    }
  }

  // const classes = themeStyles()

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={stylesCommon.agoraContainer}>
        <Box style={{ padding: 20, margin: 20, width: 800 }}>
          <div style={{ borderRadius: '32px', background: '#f9f9f9' }}>
            <>
              <h4 className={style.textModalAssignTraining}>{t('AssignTraining')}</h4>
              <form onSubmit={handleSave}>
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: '10px 50px',
                    borderRadius: '32px',
                  }}
                >
                  <Typography sx={{ paddingLeft: 10 }} className={style.label}>
                    {currentTraining
                      ? currentTraining?.title || ''
                      : userTrainingArray?.length === training.length
                      ? t('allAssignTrainings')
                      : t('training')}
                  </Typography>
                  <Input
                    select
                    variant={'outlined'}
                    sx={{ width: '80%' }}
                    //InputLabelProps={{ className: classes.label }}
                    //InputProps={{ className: classes.input }}
                    id="title"
                    name={'title'}
                    value={currentTraining?.title || ''}
                    label={''}
                    disabled={userTrainingArray?.length === training.length}
                    onChange={(e) => handleTrainingSelected(e)}
                  >
                    {userTrainingArray &&
                      training.map((item, i) => {
                        const filter = userTrainingArray.filter(
                          (itemUT) => itemUT.trainingID === item.id
                        )
                        if (!filter.length) {
                          return <MenuItem value={item.title}>{t(item.title)}</MenuItem>
                        }
                      })}
                  </Input>
                  <Box style={{ display: 'flex' }}>
                    <ListItem>
                      <Box>
                        <p style={{ marginBottom: 0, marginRight: 24 }} className={style.label}>
                          {t('Comienzo')}
                        </p>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
                          <DatePicker
                            key={'startDate'}
                            inputFormat={'DD/MM/YYYY'}
                            minDate={new Date()}
                            onChange={(e) => handleStartDateChange(e)}
                            value={userTraining.startDate}
                            label={''}
                            onError={(reason) => {
                              switch (reason) {
                                case 'invalidDate':
                                  setDateTimePickerError(t('invalidDateMessage'))
                                  break
                                case 'minDate':
                                  setDateTimePickerError(t('minDateMessage'))
                                  break
                              }
                            }}
                            renderInput={(props) => (
                              <TextField
                                id={'startDate'}
                                sx={{
                                  '& fieldset': {
                                    borderRadius: 32,
                                    border: '1px solid #E8E7EC',
                                    fontFamily: 'Montserrat',
                                  },
                                  '& .MuiInputBase-root': { fontFamily: 'Montserrat' },
                                  '& .MuiOutlinedInput-root': {
                                    border: 'none',
                                    '&.Mui-focused fieldset': {
                                      borderColor: '#ADB84E',
                                    },
                                  },
                                }}
                                size={'small'}
                                helperText={props.error && DateTimePickerError}
                                {...props}
                              />
                            )}
                            components={{
                              OpenPickerIcon: () => (
                                <img src={calendarIcon} alt="calendar" style={{ marginRight: 8 }} />
                              ),
                            }}
                          />
                        </LocalizationProvider>
                      </Box>
                    </ListItem>
                    <ListItem>
                      <Box>
                        <p style={{ marginBottom: 0, marginRight: 24 }} className={style.label}>
                          {t('Finalizar el')}
                        </p>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
                          <DatePicker
                            key={'finishDate'}
                            inputFormat={'DD/MM/YYYY'}
                            value={userTraining.finishDate}
                            minDate={startDate}
                            onChange={(e) => handleFinishDateChange(e)}
                            label={''}
                            onError={(reason) => {
                              switch (reason) {
                                case 'invalidDate':
                                  setDateTimePickerError(t('invalidDateMessage'))
                                  break
                                case 'minDate':
                                  setDateTimePickerError(t('minDateMessage'))
                                  break
                              }
                            }}
                            renderInput={(props) => (
                              <TextField
                                id={'finishDate'}
                                sx={{
                                  '& fieldset': {
                                    borderRadius: 32,
                                    border: '1px solid #E8E7EC',
                                    fontFamily: 'Montserrat',
                                  },
                                  '& .MuiInputBase-root': { fontFamily: 'Montserrat' },
                                  '& .MuiOutlinedInput-root': {
                                    border: 'none',
                                    '&.Mui-focused fieldset': {
                                      borderColor: '#ADB84E',
                                    },
                                  },
                                }}
                                size={'small'}
                                helperText={props.error && DateTimePickerError}
                                {...props}
                              />
                            )}
                            components={{
                              OpenPickerIcon: () => (
                                <img src={calendarIcon} alt="calendar" style={{ marginRight: 8 }} />
                              ),
                            }}
                          />
                        </LocalizationProvider>
                      </Box>
                    </ListItem>
                  </Box>
                  <Box
                    style={{
                      width: '80%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginTop: 40,
                    }}
                  >
                    <Box width={'100%'} sx={{ paddingRight: '16px' }}>
                      <Typography className={styles.label}>{t('amount')}</Typography>
                      <Input
                        style={{ marginRight: 40 }}
                        key={'amount'}
                        label={''}
                        type="number"
                        id={'amount'}
                        name={'amount'}
                        // InputProps={{ inputProps: { min: 0 }, className: classes.input }}
                        value={numberOfRepeating}
                        onChange={(e) => setNumberOfRepeating(Number(e.target.value))}
                        required={true}
                        fullWidth={true}
                        size={'small'}
                        variant={'outlined'}
                        // InputLabelProps={{ className: classes.label }}
                      />
                    </Box>

                    <Box width={'100%'} sx={{ paddingLeft: '16px' }}>
                      <Typography className={styles.label}>{t('RepeatType')}</Typography>
                      <Input
                        select
                        style={{}}
                        id="typeRepeating"
                        variant={'outlined'}
                        name={'typeRepeating'}
                        value={typeOfRepeating}
                        fullWidth={true}
                        label={''}
                        // InputLabelProps={{ className: classes.label }}
                        // InputProps={{ className: classes.input }}
                        onChange={(e) => setTypeOfRepeating(e.target.value as string)}
                      >
                        <MenuItem value={t('day')}>{t('day')}</MenuItem>
                        <MenuItem value={t('week')}>{t('week')}</MenuItem>
                        <MenuItem value={t('month')}>{t('month')}</MenuItem>
                      </Input>
                    </Box>
                  </Box>
                  <Box className={styles.buttons} mb={3} display="flex" justifyContent="center">
                    <Box style={{ marginRight: 10 }}>
                      <AppButton
                        theme={ButtonTheme.NewPrimary}
                        type={'submit'}
                        label={t('add')}
                        handler={(e) => handleSave(e)}
                      />
                    </Box>
                    <AppButton
                      theme={ButtonTheme.NewSecondary}
                      type={'button'}
                      label={t('cancel')}
                      handler={handleBack}
                    />
                  </Box>
                </Box>
              </form>
            </>
          </div>
          {message && (
            <Box mb={3}>
              <Alert className={styles.message} severity="success" key="message" id="message">
                {t(message)}
              </Alert>
            </Box>
          )}
        </Box>
      </Box>
    </Modal>
  )
}

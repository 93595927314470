import React from 'react'
import { TablePagination } from '@mui/material'
import { Pager } from './types'
import { useTranslation } from 'react-i18next'

// const useStyles = makeStyles({
//   root: {
//     border: '0',
//     fontSize: '13px',
//     color: '#474747',
//     fontWeight: 'normal',
//     fontFamily: 'Montserrat-Regular, sans-serif',
//     display: 'flex',
//     justifyContent: 'space-between',
//     overflow: 'hidden',
//     '& > *': {
//       margin: 'auto',
//     },
//     '& .MuiTablePagination-caption': {
//       fontSize: '13px',
//       fontWeight: 'normal',
//       fontFamily: 'Montserrat-Regular, sans-serif',
//       color: '#474747',
//     },
//   },

//   label: {
//     fontWeight: 'normal',
//     fontFamily: 'Montserrat-Regular, sans-serif',
//     fontSize: '13px',
//     color: '#474747',
//   },
// })

const rowsPerPageOptions = [10, 20, 50, 100]

type PaginationProps = {} & Pager

export function Pagination(props: PaginationProps) {
  const { t } = useTranslation()

  const [rowsPerPage, setRowsPerPage] = React.useState<number>(
    props.rowsPerPage || rowsPerPageOptions[0]
  )

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (props.handleChangeRowsPerPage) {
      setRowsPerPage(parseInt(event.target.value))
      props.handleChangeRowsPerPage(event)
    }
  }

  return (
    <TablePagination
      labelDisplayedRows={({ from, to, count }) => {
        return ''
      }}
      component="div"
      // nextIconButtonText={t('nextPage')}
      // backIconButtonText={t('prevPage')}
      labelRowsPerPage={t('resultsPerPage')}
      count={props.count}
      page={props.page}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={rowsPerPageOptions}

      onPageChange={props.handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      // Select={{
      //   style: {
      //     border: 'none',
      //     borderRadius: '3px',
      //     fontFamily: 'Montserrat-Regular, sans-serif',
      //   },
      // }}
      // classes={{
      //   root: classes.root,
      //   toolbar: classes.root,
      //   input: classes.label,
      //   select: classes.label,
      // }}
      // IconButton={{
      //   style: {
      //     color: props.page === 0 ? '#b5b8c4' : '#ADB84E',
      //     border: 'none',
      //     borderRadius: '3px',
      //     width: '30px',
      //     height: '30px',
      //   },
      // }}
      // nextIconButtonProps={{
      //   style: {
      //     color: props.count - (props.page + 1) * rowsPerPage <= 0 ? '#b5b8c4' : '#ADB84E',
      //     border: 'none',
      //     borderRadius: '3px',
      //     width: '30px',
      //     height: '30px',
      //   },
      // }}
      // onPageChange={() => {}}
    />
  )
}

import { Container, ContainerItem } from '../common/container/Container'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import { TreatmentApi } from 'modules/treatments/api/TreatmentApi'
import { TreatmentService } from '../modules/treatments/services/TreatmentService'
import { TREATMENT_API_KEY, TREATMENT_MODULE, TREATMENT_SERVICE_KEY } from '../modules/treatments'
import { ContentProps } from 'modules/treatments/container'

let container: Container

function init(p: ContentProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(TREATMENT_API_KEY, new ContainerItem({ instance: new TreatmentApi() }))

  items.set(
    TREATMENT_SERVICE_KEY,
    new ContainerItem({ instance: new TreatmentService({ apiKey: TREATMENT_API_KEY }) })
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getContentContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[TREATMENT_MODULE],
      },
    })
  }

  return container
}

import { UserTraining } from './UserTraining'
import { v4 as uuidv4 } from 'uuid'

export interface UserTrainingDTO {
  id: string
  trainingID: string
  userID: string
  startDate: Date
  finishDate: Date
  frecuency: number
  series: number
  minReps: number
  maxReps: number
  repsEspecs: string
  completed: boolean
}

export function emptyUserTrainingDTO(): UserTrainingDTO {
  return {
    id: uuidv4(),
    trainingID: '',
    userID: '',
    startDate: new Date(),
    finishDate: new Date(),
    frecuency: 0,
    series: 0,
    minReps: 0,
    maxReps: 0,
    repsEspecs: '',
    completed: false,
  }
}

export function fromModel(t: UserTraining): UserTrainingDTO {
  return {
    id: t.id,
    trainingID: t.trainingID,
    userID: t.userID,
    startDate: t.startDate,
    finishDate: t.finishDate,
    frecuency: t.frecuency,
    series: t.series,
    minReps: t.minReps,
    maxReps: t.maxReps,
    repsEspecs: t.repsEspecs,
    completed: t.completed,
  }
}

export function toModel(d: UserTrainingDTO): UserTraining {
  return new UserTraining(d)
}

import { Query } from '../../../common/api/Query'
import { UserFormValue } from './UserFormValue'
import { ReadingStatus } from '../enums/ReadingStatus'

export type UserFormProps = {
  id?: string
  userID: string
  circleID: string
  formID: string
  userFormValues: UserFormValue[]
  readingStatus: ReadingStatus
}

export class UserForm {
  private readonly _id: string | undefined
  private readonly _userID: string
  private readonly _circleID: string
  private readonly _formID: string

  private _userFormValues: UserFormValue[]
  private _readingStatus: ReadingStatus

  constructor(p: UserFormProps) {
    this._id = p.id
    this._userID = p.userID
    this._circleID = p.circleID
    this._formID = p.formID
    this._userFormValues = p.userFormValues
    this._readingStatus = p.readingStatus
  }

  get id(): string | undefined {
    return this._id
  }

  get userID(): string {
    return this._userID
  }

  get circleID(): string {
    return this._circleID
  }

  get formID(): string {
    return this._formID
  }

  get userFormValues(): UserFormValue[] {
    return [...this._userFormValues]
  }

  addUserFormValue(value: UserFormValue) {
    this._userFormValues.push(value)
  }

  removeUserFormValue(userFormID: string, formFieldID: string) {
    this._userFormValues = this._userFormValues.filter(
      (ufv) => ufv.userFormID !== userFormID || ufv.formFieldID !== formFieldID
    )
  }

  get readingStatus(): ReadingStatus {
    return this._readingStatus
  }

  set readingStatus(value: ReadingStatus) {
    this._readingStatus = value
  }
}

export class UserFormQuery extends Query<UserForm> {}

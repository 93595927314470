import { Box, Grid, TextField } from '@mui/material'
import styles from '../users/Editor.module.css'
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import {
  emptyTreatmentDTO,
  fromModel,
  TreatmentDTO,
} from '../../modules/treatments/models/TreatmentDTO'
import { getContentContainer } from '../../container/treatment-module'
import { ITreatmentService, TREATMENT_SERVICE_KEY } from '../../modules/treatments'
import { inputLabelClasses, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import genericStyle from '../../common/utils/generic.module.css'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { navigate } from '@reach/router'
import { ROUTE_DASHBOARD, ROUTE_PATIENTS } from '../../routes/routes-constants'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'
import { getUserContainer } from '../../container/user-module'
import { getAppContainer, STATUS_SERVICE_KEY } from '../../container/app'
import { IStatusService } from '../../common/status/StatusService'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { useIsOpenMenuLeftContext } from '../../common/utils/isOpenMenuLeftContext'
import { Input } from '../../pages/user-profile/userProfileStylesMui'
import { InputTextDescription } from '../resource/userProfileStylesMui'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import calendarIcon from '../../assets/calendar/calendar-newEvent.svg'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import style from '../calendar/Editor.module.css'

// const themeStyles = makeStyles(() => ({
//   input: {
//     '&.Mui-focused': {
//       '& fieldset.MuiOutlinedInput-notchedOutline': {
//         borderColor: '#a9ba37 !important',
//       },
//     },
//   },
//   inputUnderline: {
//     '&:after': {
//       borderColor: '#a9ba37 !important',
//     },
//   },
//   selectInput: {
//     '& ': {
//       width: '29rem !important',
//     },

//     '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
//       borderColor: '#a9ba37 !important',
//     },
//   },

//   label: {
//     [`&.${inputLabelClasses.shrink}`]: {
//       color: '#a9ba37',
//       marginTop: '-1%',
//       backgroundColor: 'white',
//       borderColor: '#a9ba37',
//     },
//   },
// }))

// const customThemeCalendar = createTheme({
//   palette: {
//     primary: {
//       main: '#8e9631 !important',
//       contrastText: '#ffffff !important', // --color-dark-blue
//     },
//     secondary: {
//       main: '#8e9631 !important', // --color-orange
//       contrastText: '#ffffff !important',
//     },
//   },
//   // components: {
//   //   MuiBadge: {
//   //     anchorOriginBottomRightCircle: {
//   //       transform: 'translateX(-10px) translateY(2px)',
//   //     },
//   //   },
//   // },
// })

type TreatmentsEditorProps = {
  id?: string
  userID?: string
}

const userContainer = getUserContainer()
const treatmentService = getContentContainer().get<ITreatmentService>(TREATMENT_SERVICE_KEY)
const loggedUserService = userContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
const statusService = getAppContainer().get<IStatusService>(STATUS_SERVICE_KEY)

export function Editor(props: TreatmentsEditorProps) {
  const { t } = useTranslation()
  const loggedUser = loggedUserService.get()
  const [treatment, setTreatment] = useState<TreatmentDTO>(emptyTreatmentDTO(props.userID || ''))
  const [DateTimePickerError, setDateTimePickerError] = useState<string>('')
  const { openMenuLeft } = useIsOpenMenuLeftContext()

  useEffect(() => {
    if (!props.id) return
    treatmentService.getByID(props.id).subscribe((res) => {
      if (!res) return
      setTreatment(fromModel(res))
    })
  }, [])

  const handleTreatment = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setTreatment(Object.assign({ ...treatment }, { [e.target.name]: e.target.value }))
  }

  const handleStartDate = (event: MaterialUiPickersDate) =>
    event && setTreatment(Object.assign({ ...treatment }, { startDate: event.toDate() }))

  const handleEndDate = (event: MaterialUiPickersDate) =>
    event && setTreatment(Object.assign({ ...treatment }, { endDate: event.toDate() }))

  const handleBack = () => navigate(ROUTE_PATIENTS)

  const handleSave = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (loggedUser?.id) {
      const newTreatment: TreatmentDTO = {
        id: treatment.id,
        userID: treatment.userID,
        frequency: treatment.frequency,
        reason: treatment.reason,
        duration: treatment.duration,
        name: treatment.name,
        dose: treatment.dose,
        startDate: treatment.startDate,
        endDate: treatment.endDate,
        creatorID: loggedUser?.id,
      }

      if (props.id) {
        treatmentService.update(newTreatment).subscribe((res) => {
          navigate(`${ROUTE_DASHBOARD}/${treatment.userID}`)
          statusService.sendStatus({ variant: 'success' })
        })
      } else {
        treatmentService.add(newTreatment).subscribe((res) => {
          navigate(`${ROUTE_DASHBOARD}/${treatment.userID}`)
          statusService.sendStatus({ variant: 'success' })
        })
      }
    }
  }

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginLeft: openMenuLeft ? '50px' : '',
      }}
    >
      <div
        style={{ maxWidth: openMenuLeft ? 'calc(100% - 250px)' : 'calc(100% - 300px)' }}
        className={genericStyle.pageContainer}
      >
        <div className={genericStyle.headerContainer}>
          <Typography>{t('newTreatment')}</Typography>
        </div>
        <div className={genericStyle.container}>
          <>
            <form style={{ width: '100%' }} className={styles.form} onSubmit={handleSave}>
              <Grid style={{ width: '100%' }} item className={styles.box}>
                <Box mb={2}>
                  <p style={{ marginBottom: 0, marginRight: 24 }} className={style.label}>
                    {t('name')}
                  </p>
                  <Input
                    fullWidth
                    //className={styles.fields}
                    value={treatment.name}
                    id="name"
                    name="name"
                    label={''}
                    variant="outlined"
                    required={true}
                    //InputLabelProps={{className: classes.label}}
                    //InputProps={{className: classes.input}}
                    onChange={(e) => handleTreatment(e)}
                  />
                </Box>
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  width={'100%'}
                >
                  <Box mb={2} display={'flex'} alignItems={'center'} mr={4}>
                    <p style={{ marginBottom: 0, marginRight: 20 }} className={style.label}>
                      {t('startDate')}
                    </p>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
                      <DatePicker
                        key={'startDate'}
                        inputFormat={'DD/MM/YYYY'}
                        onChange={handleStartDate}
                        value={treatment.startDate}
                        label={''}
                        onError={(reason) => {
                          switch (reason) {
                            case 'invalidDate':
                              setDateTimePickerError(t('invalidDateMessage'))
                              break
                            case 'minDate':
                              setDateTimePickerError(t('minDateMessage'))
                              break
                          }
                        }}
                        renderInput={(props) => (
                          <TextField
                            id="startDate"
                            sx={{
                              '& fieldset': {
                                borderRadius: 32,
                                border: '1px solid #E8E7EC',
                                fontFamily: 'Montserrat',
                              },
                              '& .MuiInputBase-root': { fontFamily: 'Montserrat' },
                              '& .MuiOutlinedInput-root': {
                                border: 'none',
                                '&.Mui-focused fieldset': {
                                  borderColor: '#ADB84E',
                                },
                              },
                            }}
                            size={'small'}
                            helperText={props.error && DateTimePickerError}
                            {...props}
                          />
                        )}
                        components={{
                          OpenPickerIcon: () => (
                            <img src={calendarIcon} alt="calendar" style={{ marginRight: 8 }} />
                          ),
                        }}
                      />
                    </LocalizationProvider>
                  </Box>
                  <Box display={'flex'} alignItems={'center'} mb={2}>
                    <p style={{ marginBottom: 0, marginRight: 20 }} className={style.label}>
                      {t('finishDate')}
                    </p>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
                      <DatePicker
                        key={'finishDate'}
                        inputFormat={'DD/MM/YYYY'}
                        onChange={handleEndDate}
                        value={treatment.endDate}
                        label={''}
                        onError={(reason) => {
                          switch (reason) {
                            case 'invalidDate':
                              setDateTimePickerError(t('invalidDateMessage'))
                              break
                            case 'minDate':
                              setDateTimePickerError(t('minDateMessage'))
                              break
                          }
                        }}
                        renderInput={(props) => (
                          <TextField
                            id="finishDate"
                            sx={{
                              '& fieldset': {
                                borderRadius: 32,
                                border: '1px solid #E8E7EC',
                                fontFamily: 'Montserrat',
                              },
                              '& .MuiInputBase-root': { fontFamily: 'Montserrat' },
                              '& .MuiOutlinedInput-root': {
                                border: 'none',
                                '&.Mui-focused fieldset': {
                                  borderColor: '#ADB84E',
                                },
                              },
                            }}
                            size={'small'}
                            helperText={props.error && DateTimePickerError}
                            {...props}
                          />
                        )}
                        components={{
                          OpenPickerIcon: () => (
                            <img src={calendarIcon} alt="calendar" style={{ marginRight: 8 }} />
                          ),
                        }}
                      />
                    </LocalizationProvider>
                  </Box>
                </Box>
                <Box mb={2}>
                  <p style={{ marginBottom: 0, marginRight: 24 }} className={style.label}>
                    {t('dose')}
                  </p>
                  <Input
                    fullWidth
                    //className={styles.fields}
                    value={treatment.dose}
                    id="dose"
                    name="dose"
                    label={''}
                    size={'medium'}
                    variant="outlined"
                    type={'number'}
                    //InputLabelProps={{className: classes.label}}
                    //InputProps={{className: classes.input}}
                    onChange={handleTreatment}
                  />
                </Box>
                <Box display={'flex'}>
                  <Box mr={4}>
                    <p style={{ marginBottom: 0, marginRight: 24 }} className={style.label}>
                      {t('duration')}
                    </p>
                    <Input
                      fullWidth
                      //className={styles.fields}
                      id={'duration'}
                      key={'duration'}
                      name={'duration'}
                      label={''}
                      // InputLabelProps={{ className: classes.label }}
                      // InputProps={{ className: classes.input }}
                      value={treatment.duration}
                      type={'number'}
                      onChange={(e) =>
                        setTreatment(
                          Object.assign(
                            { ...treatment },
                            { [e.target.name]: Number(e.target.value) }
                          )
                        )
                      }
                      variant="outlined"
                    />
                  </Box>
                  <Box mb={2}>
                    <p style={{ marginBottom: 0, marginRight: 24 }} className={style.label}>
                      {t('frequency')}
                    </p>
                    <Input
                      fullWidth
                      //className={styles.fields}
                      id={'frequency'}
                      key={'frequency'}
                      name={'frequency'}
                      label={''}
                      // InputLabelProps={{ className: classes.label }}
                      // InputProps={{ className: classes.input }}
                      value={treatment.frequency}
                      type={'number'}
                      onChange={(e) =>
                        setTreatment(
                          Object.assign(
                            { ...treatment },
                            { [e.target.name]: Number(e.target.value) }
                          )
                        )
                      }
                      variant="outlined"
                    />
                  </Box>
                </Box>
                <Box>
                  <p style={{ marginBottom: 0, marginRight: 24 }} className={style.label}>
                    {t('reason')}
                  </p>
                  <InputTextDescription
                    fullWidth
                    //style={{marginTop: 30}}
                    multiline
                    rows={8}
                    //className={styles.fields}
                    value={treatment.reason}
                    id="reason"
                    name="reason"
                    label={''}
                    size={'medium'}
                    variant="outlined"
                    required={true}
                    // InputLabelProps={{ className: classes.label }}
                    // InputProps={{ className: classes.input }}
                    onChange={handleTreatment}
                  />
                </Box>
                <Grid item lg={6}>
                  <Box className={styles.buttons} mb={3} display="flex" justifyContent="flex-end">
                    <Box mr={3}>
                      <AppButton
                        theme={ButtonTheme.NewSecondary}
                        type={'button'}
                        label={t('back')}
                        handler={handleBack}
                      />
                    </Box>
                    <AppButton
                      theme={ButtonTheme.NewPrimary}
                      type={'submit'}
                      label={t('add')}
                      handler={(e) => handleSave(e)}
                    />
                  </Box>
                </Grid>
              </Grid>
            </form>
          </>
        </div>
      </div>
    </div>
  )
}

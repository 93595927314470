import React, { FormEvent, useEffect, useState } from 'react'
import { ARTICLE_SERVICE_KEY } from '../../modules/content'
import { FormCard } from '../../components/form-card/FormCard'
import { Box } from '@mui/material'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { navigate } from '@reach/router'
import { ROUTE_ARTICLES } from '../../routes/routes-constants'
import { useTranslation } from 'react-i18next'
import { getContentContainer } from '../../container/content-module'
import { ArticleDTO, emptyArticleDTO, fromModel } from '../../modules/content/models/ArticleDTO'
import { ArticleService } from '../../modules/content/services/ArticleService'
import { TextFieldItem } from '../../components/form-card/TextFieldItem'
import { Alert } from '@material-ui/lab'
import genericStyle from '../../common/utils/generic.module.css'
import { getUserContainer } from '../../container/user-module'
import { ILoggedUserService } from '../../modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'
import { getAppContainer, STATUS_SERVICE_KEY } from '../../container/app'
import { IStatusService } from '../../common/status/StatusService'
import { getNotificationContainer } from '../../container/notification-module'
import { INotificationService, NOTIFICATION_SERVICE_KEY } from '../../modules/notifications'
import { useIsOpenMenuLeftContext } from '../../common/utils/isOpenMenuLeftContext'
import { Typography } from '@mui/material'
import styles from '../users/Editor.module.css'
import { Input, InputTextDescription } from '../resource/userProfileStylesMui'

type EditorProps = {
  id?: string
}
const userContainer = getUserContainer()
const loggedUserService = userContainer.get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
const contentContainer = getContentContainer()
const articleService = contentContainer.get<ArticleService>(ARTICLE_SERVICE_KEY)
const statusService = getAppContainer().get<IStatusService>(STATUS_SERVICE_KEY)
const notificationService =
  getNotificationContainer().get<INotificationService>(NOTIFICATION_SERVICE_KEY)

export function Editor(props: EditorProps) {
  const { t } = useTranslation()
  const [article, setArticle] = useState<ArticleDTO>(
    emptyArticleDTO(loggedUserService?.get()?.id || '')
  )
  const [errorMessage, setErrorMessage] = useState<string>('')
  const { openMenuLeft } = useIsOpenMenuLeftContext()

  useEffect(() => {
    if (!props.id) {
      return
    }
    articleService.getByID(props.id).subscribe((res) => {
      res && setArticle(fromModel(res))
    })
  }, [])

  const handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    setArticle(Object.assign({ ...article }, { [e.target.name]: e.target.value }))

  const goBack = () => navigate(ROUTE_ARTICLES).then()

  const validateArticle = (): boolean => {
    setErrorMessage('')
    return true
  }

  const saveArticle = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!validateArticle()) {
      return
    }
    if (props.id) {
      articleService.update(article).subscribe(() => {
        statusService.sendStatus({ variant: 'success' })
        goBack()
      })

      return
    } else {
      articleService.add(article).subscribe((res) => {
        navigate(ROUTE_ARTICLES)
        statusService.sendStatus({ variant: 'success' })
      })
    }
  }

  const handleSave = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    articleService.add(article).subscribe()
  }

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginLeft: openMenuLeft ? '50px' : '',
      }}
    >
      <Box
        style={{
          maxWidth: openMenuLeft ? 'calc(100% - 250px)' : 'calc(100% - 300px)',
          height: '100%',
        }}
        className={genericStyle.pageContainer}
      >
        <div className={genericStyle.headerContainer}>
          <Typography>
            {t('newArticle')} / {t('editArticle')}
          </Typography>
        </div>
        <Box className={genericStyle.container}>
          <form style={{ width: '100%' }} className={styles.form} onSubmit={(e) => saveArticle(e)}>
            <Box>
              <Box mb={3} className={styles.box}>
                <Typography className={styles.inputTitle}>{t('title')}</Typography>
                <Input
                  sx={{ width: '100%', '& .MuiInputBase-root': { paddingLeft: 2 } }}
                  id="title"
                  name={'title'}
                  value={article.title}
                  label={''}
                  type={'text'}
                  onChange={handleInput}
                  required={true}
                />
              </Box>

              <Box mb={3} className={styles.box}>
                <Typography className={styles.inputTitle}>{t('content')}</Typography>
                <InputTextDescription
                  multiline
                  sx={{ width: '100%', '& .MuiInputBase-root': { paddingLeft: 2, height: '100%' } }}
                  id="content"
                  name={'content'}
                  value={article.content}
                  label={''}
                  type={'text'}
                  onChange={handleInput}
                  rows={14}
                  required={true}
                />
              </Box>

              {errorMessage && (
                <Box mb={3}>
                  <Alert severity="warning" key="errorMessage" id="errorMessage">
                    {t(errorMessage)}
                  </Alert>
                </Box>
              )}

              <Box display="flex" justifyContent="flex-end">
                <Box mr={2}>
                  <AppButton
                    theme={ButtonTheme.NewSecondary}
                    type={'button'}
                    label={t('cancel')}
                    handler={goBack}
                  />
                </Box>
                <AppButton
                  theme={ButtonTheme.NewPrimary}
                  type={'button'}
                  label={t('save')}
                  handler={saveArticle}
                />
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
    </div>
  )
}

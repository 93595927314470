import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material'
import { Actions, Field, Sort } from './types'

type HeadProps<T> = {
  readonly fields: Field<T>[]
  readonly sort?: Sort<T>
  readonly actions?: Actions<T>
}

export function HeadTreatment<T extends { [key: string]: any }>(props: HeadProps<T>) {
  const handleSort = (name: keyof T) => () => props.sort?.handleSort && props.sort.handleSort(name)

  // const useStyles = makeStyles((_) => ({
  //   head: {
  //     fontWeight: 500,
  //     background: 'transparent',
  //     color: '#515151',
  //     fontSize: '14px',
  //     borderBottom: '3px solid #a9ba37',
  //     padding: '16px',
  //     fontFamily: 'Montserrat-SemiBold, sans-serif',
  //     // minWidth: '91px'
  //     // width: '13.98vw',
  //   },
  //   actions: {
  //     fontWeight: 500,
  //     background: 'transparent',
  //     color: '#515151',
  //     fontSize: '14px',
  //     borderBottom: '3px solid #a9ba37',
  //     fontFamily: 'Montserrat-SemiBold, sans-serif',
  //     // minWidth: '91px'
  //     // width: '13.98vw',
  //   },
  // }))

  // const classes = useStyles()

  const headCells = Object.values(props.fields).map((f) => (
    <TableCell
      style={{ width: `${100 / Object.keys(props.fields).length}%` }}
      // className={classes.head}
      key={f.name as string}
    >
      {f.sortable ? (
        <TableSortLabel
          active={f.name === props.sort?.name}
          direction={props.sort?.direction || 'asc'}
          onClick={handleSort(f.name)}
        >
          {f.label}
        </TableSortLabel>
      ) : (
        f.label
      )}
    </TableCell>
  ))

  return (
    <TableHead>
      <TableRow>
        {headCells}
        {props.actions && props.actions.actionsColumn && (
          <TableCell
            style={{ width: `${100 / Object.keys(props.fields).length}%` }}
            // className={classes.actions}
          >
            {props.actions.actionsColumn}
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  )
}

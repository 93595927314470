import { User, UserDTO, UserQuery } from '../models/User'
import { Container, IInit } from '../../../common/container/Container'
import { Observable } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { IStatusService } from '../../../common/status/StatusService'
import { IUserApi } from '../api/UserApi'
import { ItemList } from '../../../common/models/ItemList'
import { RelatedUser } from '../models/RelatedUser'
import { FamiliarData, FamiliarDataQuery } from '../models/FamiliarData'
import { FamiliarDataDTO } from '../models/FamiliarDataDTO'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { Role } from '../models/Role'
import { UpdatePasswordDTO } from '../models/UpdatePasswordDTO'
import { UpdatePassword } from '../models/UpdatePassword'

type Props = {
  apiKey: symbol
}

export interface IUserService extends IInit {
  getByID(id: string): Observable<User | undefined>

  getFilteredItems(q: Query<UserQuery>): Observable<User[]>

  getFilteredList(q: Query<UserQuery>): Observable<ItemList<User>>

  add(e: UserDTO, language: string): Observable<UserDTO | undefined>

  update(e: UserDTO): Observable<User | undefined>

  getRelated(id: string, q: Query<User>): Observable<RelatedUser | undefined>

  getByDoctorID(q: Query<UserQuery>): Observable<ItemList<User>>

  removeRelated(id: string, rID: string): Observable<boolean>

  getUserRolesByCircle(q: Query<User>): Observable<ItemList<User>>

  getInformationByID(id: string): Observable<FamiliarData | undefined>

  getInformationByUser(q: Query<FamiliarDataQuery>): Observable<ItemList<FamiliarData>>

  addInformation(e: FamiliarDataDTO): Observable<FamiliarData | undefined>

  updateInformation(e: FamiliarDataDTO): Observable<FamiliarData | undefined>

  deleteInformation(id: string): Observable<boolean>

  getPermissions(): Observable<ItemList<Role>>

  getUsersByRole(roleName: string): Observable<ItemList<User>>

  changePassword(e: UpdatePasswordDTO): Observable<UpdatePassword | undefined>
}

export class UserService implements IUserService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IUserApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IUserApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(e: UserDTO, language: string): Observable<UserDTO | undefined> {
    return this._api.add(e, language)
  }

  getByID(id: string): Observable<User | undefined> {
    return this._api.getByID(id)
  }

  getFilteredItems(q: Query<UserQuery>): Observable<User[]> {
    return this._api.getFilteredItems(q).pipe()
  }

  getFilteredList(q: Query<UserQuery>): Observable<ItemList<User>> {
    return this._api.getFilteredList(q).pipe()
  }

  update(e: UserDTO): Observable<User | undefined> {
    return this._api.update(e)
  }

  getRelated(id: string, q: Query<User>): Observable<RelatedUser | undefined> {
    return this._api.getRelated(id, q)
  }

  getByDoctorID(q: Query<UserQuery>): Observable<ItemList<User>> {
    return this._api.getByDoctorID(q)
  }

  removeRelated(id: string, rID: string): Observable<boolean> {
    return this._api.removeRelated(id, rID)
  }

  getUserRolesByCircle(q: Query<User>): Observable<ItemList<User>> {
    return this._api.getUserRolesByCircle(q)
  }

  getInformationByID(id: string): Observable<FamiliarData | undefined> {
    return this._api.getInformationByID(id)
  }

  getInformationByUser(q: Query<FamiliarDataQuery>): Observable<ItemList<FamiliarData>> {
    return this._api.getInformationByUser(q)
  }

  addInformation(e: FamiliarDataDTO): Observable<FamiliarData | undefined> {
    return this._api.addInformation(e)
  }

  updateInformation(e: FamiliarDataDTO): Observable<FamiliarData | undefined> {
    return this._api.updateInformation(e)
  }

  deleteInformation(id: string): Observable<boolean> {
    return this._api.deleteInformation(id)
  }

  getPermissions(): Observable<ItemList<Role>> {
    return this._api.getPermissions()
  }

  getUsersByRole(roleName: string): Observable<ItemList<User>> {
    return this._api.getUsersByRole(roleName)
  }

  changePassword(e: UpdatePasswordDTO): Observable<UpdatePassword | undefined> {
    return this._api.changePassword(e)
  }
}

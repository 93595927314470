import React, { useState } from 'react'
import LineChart from '../../components/lineChart/LineChart'
import StackedBarChart from '../../components/stackedBarChart/StackedBarChart'
import { Box, Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import StatisticsModal, { DateFilter } from '../../features/statistics/StatisticsModal'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import styles from '../statisticsBox/StatisticsBox.module.css'
import { useIsOpenMenuLeftContext } from '../../common/utils/isOpenMenuLeftContext'

interface StatisticsBoxProps {
  titulo: string
  data: any[]
  dataSetsNames: string[]
  tipoChart: string
  stylesText?: string
  stylesHistorial?: string
  styleInfoBox?: string
  dashboard?: boolean
}

const StatisticsBox = (props: StatisticsBoxProps) => {
  //UseState para el control del modal
  const [open, setOpen] = React.useState(false)
  const modalClose = () => setOpen(false)
  const modalOpen = () => setOpen(true)
  const { openMenuLeft } = useIsOpenMenuLeftContext()

  const renderChart = (param: string, props: StatisticsBoxProps, date: DateFilter) => {
    switch (param) {
      case 'line':
        return (
          <LineChart
            titulo={props.titulo}
            data={props.data}
            dataSetsNames={props.dataSetsNames}
            filterDate={date}
            eje={false}
            graphicsPoints={0}
          />
        )

      case 'stackedBar':
        return (
          <StackedBarChart
            titulo={props.titulo}
            data={props.data}
            dataSetsNames={props.dataSetsNames}
            filterDate={date}
            eje={false}
          />
        )
    }
  }

  const [date, setDate] = useState<DateFilter>({
    startDate: new Date(),
    finishDate: new Date(),
  })

  const handlerChangeFinishDate = (event: MaterialUiPickersDate) => {
    if (!event) return

    setDate(Object.assign({ ...date }, { startDate: date.startDate, finishDate: event.toDate() }))
  }

  const handlerChangeStartDate = (event: MaterialUiPickersDate) => {
    if (!event) return
    setDate(Object.assign({ ...date }, { startDate: event.toDate(), finishDate: date.finishDate }))
  }

  const { t } = useTranslation()
  return (
    <>
      <Box
        p={3}
        sx={{
          backgroundColor: 'white',
          borderRadius: '32px',
          border: '1px solid #a9ba37',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        }}
      >
        <Box className={styles.containerStatistics}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'flex-start'}>
            <h3
              style={{ fontSize: props.dashboard && openMenuLeft ? 10 : '' }}
              className={props.stylesText ? props.stylesText : styles.chartBoxTitle}
            >
              {props.titulo}
            </h3>
            <Button
              style={{ fontSize: props.dashboard && openMenuLeft ? 10 : '' }}
              variant="text"
              onClick={modalOpen}
              className={props.stylesHistorial ? props.stylesHistorial : styles.StatsHistory}
            >
              {t('history')}{' '}
            </Button>
          </Box>
          <Typography
            color={'green'}
            variant={'h5'}
            style={{ fontSize: props.dashboard ? 14 : openMenuLeft ? 16 : '' }}
            className={props.styleInfoBox ? props.styleInfoBox : styles.chartBoxInfo}
          >
            {props.titulo == 'Intensidad / Dia'
              ? `${props.data[0]?.very_active ? props.data[0]?.very_active : 0} Minutos muy activos`
              : props.titulo == 'Horas de sueño / Dia'
              ? `${props.data[0]?.value ? Math.trunc(props.data[0]?.value / 60) : 0} horas y ${
                  props.data[0]?.value ? props.data[0]?.value % 60 : 0
                } minutos de sueño`
              : `${props.data[0]?.value ? props.data[0]?.value : 0} ${props.titulo.substring(
                  0,
                  props.titulo.length - 6
                )}`}
          </Typography>
        </Box>
        <Box className={styles.boxCharts}>{renderChart(props.tipoChart, props, date)}</Box>
      </Box>

      <StatisticsModal hookOpen={open} changeState={modalClose} eventProps={props} />
    </>
  )
}

export default StatisticsBox

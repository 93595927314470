import React from 'react'
import { Calendar as C } from '../../features/calendar'
import { RouteProps } from '../../routes/AppRouter'
import { useTitleHeaderContext } from '../../common/utils/TitleHeaderContext'
import { Box } from '@mui/material'
import logoCareme from '../../assets/caremeLogoMain.svg'
import style from '../layout/Main.module.css'

type CalendarProps = {
  id?: string
  selectedDate?: Date
} & RouteProps

export function Calendar(props: CalendarProps) {
  const title = props.title || ''
  const { setTitle } = useTitleHeaderContext()

  setTitle(title)
  const state: CalendarProps = props.location?.state as CalendarProps

  return (
    <>
      <C id={props.id} selectedDate={state?.selectedDate} />
      <Box display={'flex'} justifyContent={'flex-end'}>
        <img src={logoCareme} alt="Logo Careme" className={style.logo} />
      </Box>
    </>
  )
}

import { Box, Modal } from '@mui/material'
import styles from './AssignDeviceModal.module.css'
import style from '../../components/modal/CustomModal.module.css'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { inputLabelClasses, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { getContentContainer } from '../../container/statistics-module'
import { IWearableService, WEARABLE_SERVICE_KEY } from '../../modules/statistics'
import {
  emptyWearableDTO,
  fromModel,
  WearableDTO,
} from '../../modules/statistics/models/WearableDTO'
import { Wearable } from '../../modules/statistics/models/Wearable'
import { ItemList } from '../../common/models/ItemList'
import { Input } from '../../pages/user-profile/userProfileStylesMui'

type AssignDeviceModalProps = {
  open: boolean
  handleClose: () => void
  idUser: string
  wearableUser: ItemList<Wearable>
}

const wearableService = getContentContainer().get<IWearableService>(WEARABLE_SERVICE_KEY)

export const AssignDeviceModal = (props: AssignDeviceModalProps) => {
  const { t } = useTranslation()
  const [wearable, setWearable] = useState<WearableDTO>(emptyWearableDTO())

  // const themeStyles = makeStyles(() => ({
  //   input: {
  //     '&.Mui-focused': {
  //       '& fieldset.MuiOutlinedInput-notchedOutline': {
  //         borderColor: '#a9ba37 !important',
  //       },
  //     },
  //   },
  //   inputUnderline: {
  //     '&:after': {
  //       borderColor: '#a9ba37 !important',
  //     },
  //   },
  //   selectInput: {
  //     '& ': {
  //       width: '29rem !important',
  //     },

  //     '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
  //       borderColor: '#a9ba37 !important',
  //     },
  //   },

  //   label: {
  //     [`&.${inputLabelClasses.shrink}`]: {
  //       color: '#a9ba37',
  //       marginTop: '-1%',
  //       backgroundColor: 'white',
  //       borderColor: '#a9ba37',
  //     },
  //   },
  // }))
  // const classes = themeStyles()

  useEffect(() => {
    if (!props.wearableUser.items[0]?.id) return
    wearableService.getByID(props.wearableUser.items[0].id).subscribe((res) => {
      if (!res) return
      setWearable(fromModel(res))
    })
  }, [props.idUser])

  const handlerAssignMac = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setWearable(Object.assign({ ...wearable }, { [e.target.name]: e.target.value }))
  }
  const assignMac = () => {
    const newWearable: WearableDTO = {
      id: wearable.id,
      idUser: props.idUser,
      mac: wearable.mac,
      date: new Date(),
    }

    if (props.wearableUser.items[0]?.mac) {
      wearableService.update(newWearable).subscribe(() => props.handleClose())
    } else {
      wearableService.add(newWearable).subscribe(() => props.handleClose())
    }
  }

  const deleteMac = () => {
    wearableService.delete(wearable.id).subscribe(() => props.handleClose())
  }

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Box className={style.agoraContainer}>
        <Box className={styles.containerModal}>
          <Box style={{ width: '100%' }}>
            <Typography className={styles.label}>{t('MAC')}</Typography>
            <Input
              value={wearable.mac}
              id="mac"
              name="mac"
              label={''}
              variant="outlined"
              required={true}
              fullWidth={true}
              // InputLabelProps={{ className: classes.label }}
              // InputProps={{ className: classes.input }}
              onChange={handlerAssignMac}
            />
          </Box>
          <Box
            style={{
              display: 'flex',
              marginTop: '30px',
              marginLeft: '10px',
              justifyContent: 'flex-end',
            }}
          >
            <AppButton
              label={t('assign')}
              theme={ButtonTheme.NewPrimary}
              handler={assignMac}
              type={'button'}
            />
            {wearable.mac.length > 0 && (
              <Box style={{ marginLeft: 8 }}>
                <AppButton
                  label={t('delete')}
                  theme={ButtonTheme.NewPrimary}
                  handler={deleteMac}
                  type={'button'}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

import { AppTable, Field } from '../../components/table'
import React, { useEffect, useState } from 'react'
import { Actions, Pager } from '../../components/table/types'
import editIcon from '../../assets/articles/edit.svg'
import deleteIcon from '../../assets/articles/delete.svg'
import { emptyList, ItemList } from '../../common/models/ItemList'
import { useTranslation } from 'react-i18next'
import { getContentContainer } from '../../container/treatment-module'
import { ITreatmentService, TREATMENT_SERVICE_KEY } from '../../modules/treatments'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { IUserService, LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from '../../modules/users'
import { getUserContainer } from '../../container/user-module'
import { Treatment, TreatmentQuery } from '../../modules/treatments/models/Treatment'
import { dateToDateString } from '../../common/utils/date'
import { reduceString } from '../../common/utils/strings'
import { Query, QueryParam } from '../../common/api/Query'
import { ROUTE_TREATMENTS } from '../../routes/routes-constants'
import { navigate } from '@reach/router'
import { CustomModal } from '../../components/modal/CustomModal'
import { Box, Modal } from '@mui/material'
import { Table as TableTreatment } from '@mui/material'
import { TableContainer } from '@mui/material'
import { useIsOpenMenuLeftContext } from '../../common/utils/isOpenMenuLeftContext'
import { HeadTreatment } from '../../components/table/HeadTreatment'
import { BodyTreatment } from '../../components/table/BodyTreatment'

type TreatmentTableProps = {
  id?: string
}

const userContainer = getUserContainer()
const treatmentService = getContentContainer().get<ITreatmentService>(TREATMENT_SERVICE_KEY)
const loggedUserService = userContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
const userService = userContainer.get<IUserService>(USER_SERVICE_KEY)

export function Table(props: TreatmentTableProps) {
  const { t } = useTranslation()
  const loggedUser = loggedUserService.get()
  const [treatments, setTreatments] = useState<Treatment[]>([])
  const [treatmentsPerPage, setTreatmentsPerPage] = useState<number>(10)
  const [page, setPage] = useState<number>(0)
  const [pager, setPager] = useState<Pager>()
  const [users, setUsers] = useState<Map<string, string>>(new Map())
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [count, setCount] = useState<number>(0)
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [currentTreatment, setCurrentTreatment] = useState<Treatment>()
  const { openMenuLeft } = useIsOpenMenuLeftContext()
  const innerWidth = window.innerWidth

  useEffect(() => {
    if (!isLoading || !props.id) {
      return
    }
    treatmentService
      .getFilteredList(
        new Query({
          query: [new QueryParam<TreatmentQuery>('userID', props.id)],
          pager: { limit: treatmentsPerPage, offset: page * treatmentsPerPage },
        })
      )
      .subscribe((res) => {
        setCount(res.count)
        setTreatments(res.items)
        setIsLoading(false)
      })
  }, [isLoading, props.id])

  const handlePaginationChange = (event: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setTreatmentsPerPage(10)
      return
    }
    setTreatmentsPerPage(Number.parseInt(event.target.value))
  }
  useEffect(() => {
    setIsLoading(true)
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: treatmentsPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, treatmentsPerPage])

  const editTreatment = (t: Treatment) => {
    setCurrentTreatment(t)
    navigate(`${ROUTE_TREATMENTS}/${t.id}`)
  }

  const removeTreatment = (t: Treatment) => {
    setCurrentTreatment(t)
    setOpenDeleteModal(true)
  }

  const handleCloseDeleteModal = () => setOpenDeleteModal(false)

  const handleDeleteTreatment = () => {
    if (currentTreatment?.id)
      treatmentService.delete(currentTreatment.id).subscribe((_) => setIsLoading(true))
    setOpenDeleteModal(false)
    setIsLoading(true)
  }

  const isCreator = (t: Treatment): boolean => t.creatorID === loggedUser?.id

  const fields: Field<Treatment>[] = [
    {
      name: 'name',
      label: t('name'),
    },
    {
      name: 'startDate',
      label: t('startDate'),
      renderFunc: (f, i) => new Date(i.startDate).toLocaleDateString(),
    },
    {
      name: 'endDate',
      label: t('finishDate'),
      renderFunc: (f, i) => {
        if (!i.endDate) return ''
        if (new Date(i.endDate) < new Date(1910, 1)) return 'Actualmente'
        return dateToDateString(new Date(i.endDate))
      },
    },
    {
      name: 'duration',
      label: t('duration'),
    },
    {
      name: 'frequency',
      label: t('frequency'),
    },
    {
      name: 'dose',
      label: t('dose'),
      renderFunc: (f, i) => reduceString(i.dose, 100),
    },
    {
      name: 'reason',
      label: t('reason'),
      renderFunc: (f, i) => reduceString(i.reason || '', 100),
    },
  ]

  const actions: Actions<Treatment> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: editTreatment,
        icon: editIcon,
        label: 'edit',
        hidden: (t) => !isCreator(t),
      },
      {
        handler: removeTreatment,
        icon: deleteIcon,
        label: 'delete',
        hidden: (t) => !isCreator(t),
      },
    ],
  }

  return (
    <Box>
      <Modal open={openDeleteModal} onClose={handleCloseDeleteModal}>
        <CustomModal
          title={t('deleteTreatment')}
          warningText={t('irreversibleTreatmentAction')}
          handleClose={handleCloseDeleteModal}
          handleSave={handleDeleteTreatment}
        />
      </Modal>
      {/*<AppTable
        items={treatments}
        rowKeyField="id"
        fields={fields}
        actions={actions}
        pager={pager}
        isLoading={isLoading}
      />*/}
      <TableContainer
        sx={{
          overflowX: openMenuLeft && innerWidth > 1500 ? 'hidden' : 'auto',
        }}
      >
        <TableTreatment
          sx={{
            border: 'none',
            background: 'transparent',
            margin: 0,
            '& td': {
              border: 'none',
              '&:last-child': {
                border: 'none',
              },
              '&:first-child': {
                borderLeftWidth: 1,
                borderLeft: 'none',
                borderColor: '#ADB84E',
                color: '#ADB84E',
                fontFamily: 'Montserrat-SemiBold, sans-serif',
              },
            },
            '& tr': {
              border: 'none',
            },
            '& th': {
              border: 'none',
            },
          }}
          style={{ border: 'none' }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <HeadTreatment fields={fields} actions={actions} />
            <BodyTreatment
              actions={actions}
              fields={fields}
              items={treatments}
              rowKeyField={'id'}
              isLoading={isLoading}
            />
          </div>
        </TableTreatment>
      </TableContainer>
      {!isLoading && treatments.length <= 0 && (
        <Box>
          <h4>{t('withoutAssignedMedication')}</h4>
        </Box>
      )}
    </Box>
  )
}

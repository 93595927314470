import { Box, Typography } from '@mui/material'
import { RouteProps } from '../../routes/AppRouter'
import styles from './CookiesPolicy.module.css'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HeaderPolicy } from '../../components/header-policy/HeaderPolicy'
import { useTitleHeaderContext } from '../../common/utils/TitleHeaderContext'
import genericStyle from '../../common/utils/generic.module.css'
import logoCareme from '../../assets/caremeLogoMain.svg'
import styleMain from '../layout/Main.module.css'
import { useIsOpenMenuLeftContext } from '../../common/utils/isOpenMenuLeftContext'
import { LoggedUser } from '../../modules/users/models/LoggedUser'
import { getUserContainer } from '../../container/user-module'
import { ILoggedUserService } from '../../modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'

const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)

export function CookiesPolicy(props: RouteProps) {
  const loggedUser = loggedUserService.get()
  const title = props.title || ''
  const { setTitle } = useTitleHeaderContext()
  const [user, setUser] = useState<LoggedUser | undefined>(loggedUser)
  const openMenuLeft = useIsOpenMenuLeftContext()
  const innerWidth = window.innerWidth

  setTitle(title)
  const { t } = useTranslation()

  useEffect(() => {
    loggedUserService.getObservable().subscribe((lu) => {
      setUser(lu)
    })
  }, [])

  return (
    <>
      {user ? (
        <>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              marginLeft: innerWidth > 900 ? (openMenuLeft ? '50px' : '') : '',
            }}
          >
            <Box
              style={{
                maxWidth:
                  innerWidth > 900
                    ? openMenuLeft
                      ? 'calc(100% - 250px)'
                      : 'calc(100% - 300px)'
                    : '95%',
                position: 'relative',
              }}
              className={genericStyle.pageContainer}
            >
              <Box sx={{ overflow: 'auto' }} className={genericStyle.container}>
                <Box display={'flex'} justifyContent={'center'}>
                  <Box className={styles.content}>
                    <h4 className={styles.titleParraf}>{t('ApplicableRegulationsTitle')}</h4>
                    <Typography className={styles.parraph}>
                      {t('ApplicableRegulationsParraf')}
                    </Typography>
                    <h4 className={styles.titleParraf}>{t('TypesOfCookiesTitle')}</h4>
                    <Typography className={styles.parraph}>{t('TypesOfCookiesParraf')}</Typography>
                    <h4 className={styles.titleParraf}>{t('InformationPrincipleTitle')}</h4>
                    <Typography className={styles.parraph}>
                      {t('InformationPrincipleParraf')}
                    </Typography>
                    <h4 className={styles.titleParraf}>{t('PrincipleOfConsentTitle')}</h4>
                    <Typography className={styles.parraph}>
                      {t('PrincipleOfConsentParraf')}
                    </Typography>
                    <h4 className={styles.titleParraf}>{t('OptionalNatureTitle')}</h4>
                    <Typography className={styles.parraph}>{t('OptionalNatureParraf')}</Typography>
                    <h4 className={styles.titleParraf}>{t('CookiesDeactivationTitle')}</h4>
                    <Typography className={styles.parraph}>
                      {t('CookiesDeactivationParraf')}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </div>
          <Box display={'flex'} justifyContent={'flex-end'}>
            <img src={logoCareme} alt="Logo Careme" className={styleMain.logo} />
          </Box>
        </>
      ) : (
        <Box className={styles.containter}>
          <Box display={'flex'} justifyContent={'center'}>
            <HeaderPolicy title={t('cookiesPolicy')} />
            <Box className={styles.content}>
              <h1 className={styles.titleHeader}>{t('cookiesPolicy')}</h1>
              <h4 className={styles.titleParraf}>{t('ApplicableRegulationsTitle')}</h4>
              <Typography className={styles.parraph}>{t('ApplicableRegulationsParraf')}</Typography>
              <h4 className={styles.titleParraf}>{t('TypesOfCookiesTitle')}</h4>
              <Typography className={styles.parraph}>{t('TypesOfCookiesParraf')}</Typography>
              <h4 className={styles.titleParraf}>{t('InformationPrincipleTitle')}</h4>
              <Typography className={styles.parraph}>{t('InformationPrincipleParraf')}</Typography>
              <h4 className={styles.titleParraf}>{t('PrincipleOfConsentTitle')}</h4>
              <Typography className={styles.parraph}>{t('PrincipleOfConsentParraf')}</Typography>
              <h4 className={styles.titleParraf}>{t('OptionalNatureTitle')}</h4>
              <Typography className={styles.parraph}>{t('OptionalNatureParraf')}</Typography>
              <h4 className={styles.titleParraf}>{t('CookiesDeactivationTitle')}</h4>
              <Typography className={styles.parraph}>{t('CookiesDeactivationParraf')}</Typography>
            </Box>
          </Box>
        </Box>
      )}
    </>
  )
}

import React from 'react'
import { RouteProps } from '../../routes/AppRouter'
import { CurrentNavHeader } from '../../components/current-nav-header/CurrentNavHeader'
import { AllTrainings as TR } from '../../features/training'
import { useTranslation } from 'react-i18next'
import { useTitleHeaderContext } from '../../common/utils/TitleHeaderContext'
import logoCareme from '../../assets/caremeLogoMain.svg'
import style from '../layout/Main.module.css'
import { Box } from '@mui/material'

export function AllTrainings(props: RouteProps) {
  const title = props.title || ''
  const { setTitle } = useTitleHeaderContext()

  setTitle(title)
  const { t } = useTranslation()

  return (
    <>
      <TR id={props.id} />
      <Box display={'flex'} justifyContent={'flex-end'}>
        <img src={logoCareme} alt="Logo Careme" className={style.logo} />
      </Box>
    </>
  )
}

import React from 'react'
import { Box, Table, TableContainer } from '@mui/material'
import { Head } from './Head'
import { Pagination } from './Pagination'
import { Body } from './Body'
import { Actions, Field, Pager, Search, Sort } from './types'
import { Searcher } from './Searcher'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { ListTable } from './ListTable'
import { useIsOpenMenuLeftContext } from '../../common/utils/isOpenMenuLeftContext'

const DEFAULT_ROW_KEY = 'id'

export type TableProps<T, Q> = {
  readonly fields: Field<T>[]
  readonly items: T[]
  readonly rowKeyField: keyof T

  readonly actions?: Actions<T>
  readonly search?: Search<Q>
  readonly sort?: Sort<T>
  readonly pager?: Pager
  readonly isLoading?: boolean
}

export function AppTable<T extends { [key: string]: any }, Q extends { [key: string]: any }>(
  props: TableProps<T, Q>
) {
  const { openMenuLeft } = useIsOpenMenuLeftContext()
  const innerWidth = window.innerWidth
  return (
    <>
      {/* <Box display={'flex'} justifyContent={'flex-end'}>
        {props.search && <Searcher search={props.search} />}
      </Box>*/}
      <TableContainer
        sx={{
          overflowX: openMenuLeft && innerWidth > 1500 ? 'hidden' : 'auto',
          // marginLeft: openMenuLeft
          //   ? innerWidth > 1300
          //     ? '180px'
          //     : '8px'
          //   : innerWidth > 1200
          //   ? '68px'
          //   : '8px',
          // marginRight: openMenuLeft
          //   ? innerWidth > 1300
          //     ? '134px'
          //     : '8px'
          //   : innerWidth > 1100
          //   ? '8px'
          //   : '8px',
        }}
      >
        <Table
          sx={{
            border: 'none',
            background: 'transparent',
            width: innerWidth < 1300 ? '95%' : '95%',
            margin: 0,
            '& td': {
              borderWidth: 1,
              borderStyle: 'dotted',
              borderColor: '#ADB84E',
              '&:last-child': {
                border: 'none',
              },
              '&:first-child': {
                borderLeftWidth: 1,
                borderLeft: 'none',
                borderColor: '#ADB84E',
                color: '#ADB84E',
                fontFamily: 'Montserrat-SemiBold, sans-serif',
              },
            },
            '& tr': {
              borderStyle: 'dotted',
              borderColor: '#ADB84E',
            },
            '& th': {
              borderColor: '#ADB84E',
            },
          }}
          style={{ border: 'none' }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: openMenuLeft ? '' : '100%',
              marginLeft: innerWidth < 900 ? '' : openMenuLeft ? 30 : '',
              marginRight: innerWidth < 900 ? '' : openMenuLeft ? 30 : '',
            }}
          >
            <Head fields={props.fields} sort={props.sort} actions={props.actions} />
            <Body
              actions={props.actions}
              fields={props.fields}
              items={props.items}
              rowKeyField={props.rowKeyField || DEFAULT_ROW_KEY}
              isLoading={props.isLoading}
            />
          </div>
        </Table>
      </TableContainer>
    </>
  )
}

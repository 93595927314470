import React from 'react'
import { FormDTO } from 'modules/forms/models/FormDTO'
import { FormFieldDTO } from '../../modules/forms/models/FormDTO'
import { FormFieldType } from '../../modules/forms/enums/FormFieldType'
import { TextFieldItem } from '../../components/form-card/TextFieldItem'
import { Box, Select, Checkbox, RadioGroup, Radio, FormControlLabel, Chip } from '@mui/material'
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  KeyboardDateTimePicker,
} from '@material-ui/pickers'
import CustomToolbar from '../calendar/CustomToolbar'
import { useTranslation } from 'react-i18next'
import styles from '../form-patients/View.module.css'
import { MenuItem } from '@mui/material'

export function PreviewForm(props: FormDTO) {
  const { title, formFields } = props
  const { t } = useTranslation()

  const generateTextField = (formField: FormFieldDTO, multiline: boolean) => {
    return (
      <Box mb={3}>
        <p>{formField.title}</p>
        <TextFieldItem
          key={formField.id}
          field={formField.id}
          type={formField.type === FormFieldType.Number ? 'number' : 'text'}
          label={''}
          rows={multiline ? 10 : undefined}
          value=""
          handleChange={() => {}}
          required={formField?.required}
        />
      </Box>
    )
  }

  const generateDateField = (formField: FormFieldDTO) => {
    let date = new Date()
    return (
      <Box mb={3}>
        <p>{formField.title}</p>
        <KeyboardDatePicker
          key={formField.id}
          id={formField.id}
          name={formField.id}
          autoOk={true}
          variant="inline"
          format="DD/MM/YYYY"
          margin="normal"
          label={''}
          value={date}
          onChange={() => {}}
          ToolbarComponent={CustomToolbar}
          required={formField.required}
        />
      </Box>
    )
  }

  const generateSelectField = (formField: FormFieldDTO) => {
    return (
      <Box mb={3}>
        <p>{formField.title}</p>
        <Select
          key={formField.id}
          id={formField.id}
          variant={'outlined'}
          onChange={() => {}}
          className={styles.select}
          multiple={false}
          required={formField.required}>
          {formField.optionValues.map((k) => (
            <MenuItem key={k} value={k}>
              {k}
            </MenuItem>
          ))}
        </Select>
      </Box>
    )
  }
  const generateMultipleSelectField = (formField: FormFieldDTO) => {
    return (
      <Box mb={3}>
        <p>{formField.title}</p>
        <Select
          key={formField.id}
          id={formField.id}
          variant={'outlined'}
          value={[]}
          onChange={() => {}}
          multiple={true}
          name={'selectorMultiple'}
          className={styles.select}
          required={formField.required}>
          {formField.optionValues.map((k) => (
            <MenuItem key={k} value={k}>
              {k}
            </MenuItem>
          ))}
        </Select>
      </Box>
    )
  }

  const generateCheckboxField = (formField: FormFieldDTO) => {
    return (
      <Box mb={3}>
        <p className={styles.checkbox}>{formField.title}</p>
        <Checkbox name={formField.id} onChange={() => {}} />
      </Box>
    )
  }

  const generateRadioButtonField = (formField: FormFieldDTO) => {
    const radioButtons = Array.isArray(formField.optionValues) ? formField.optionValues : []
    return (
      radioButtons.length > 0 && (
        <Box mb={3}>
          <p>{formField.title}</p>
          <RadioGroup name={formField.title}>
            {radioButtons.map((o, i) => (
              <FormControlLabel
                key={`${o}-${i}`}
                value={o}
                control={<Radio required={formField.required} />}
                label={o}
                onChange={() => {}}
              />
            ))}
          </RadioGroup>
        </Box>
      )
    )
  }

  /*  const generateFileField = (formField: FormFieldDTO) => {
    return (
      <Box mb={3}>
        <p>{formField.title}</p>
        <FileItem
          key={formField.id}
          field={formField.id}
          filesID={[]}
          handleFiles={() => {}}
          userID=""
          cleanFiles={false}
          uploadRemoveFileDirectly={true}
          disabled={true}
        />
      </Box>
    )
  }*/

  const generateTimeField = (formField: FormFieldDTO) => {
    let date = new Date()
    return (
      <Box>
        <p>{formField.title}</p>
        <KeyboardTimePicker
          key={formField.id}
          id={formField.id}
          name={formField.id}
          variant="inline"
          autoOk
          format="HH:mm"
          margin="normal"
          label={''}
          value={date}
          invalidDateMessage={t('invalidHourMessage')}
          onChange={() => {}}
        />
      </Box>
    )
  }

  const generateDateTimeField = (formField: FormFieldDTO) => {
    let date = new Date()
    return (
      <Box>
        <p>{formField.title}</p>
        <KeyboardDateTimePicker
          key={formField.id}
          id={formField.id}
          name={formField.id}
          variant="inline"
          autoOk
          format="DD/MM/YYYY HH:mm"
          margin="normal"
          label={''}
          value={date}
          invalidDateMessage={t('invalidHourMessage')}
          onChange={() => {}}
        />
      </Box>
    )
  }

  const generateField = (formField: FormFieldDTO) => {
    switch (formField.type) {
      case FormFieldType.Text:
      case FormFieldType.TextArea:
      case FormFieldType.Number:
        const multiline = formField.type === FormFieldType.TextArea
        return generateTextField(formField, multiline)
      case FormFieldType.Date:
        return generateDateField(formField)

      case FormFieldType.Select:
        return generateSelectField(formField)
      case FormFieldType.MultiSelect:
        return generateMultipleSelectField(formField)

      case FormFieldType.CheckBox:
        return generateCheckboxField(formField)

      case FormFieldType.RadioButton:
        return generateRadioButtonField(formField)

      /*  case FormFieldType.File:
        return generateFileField(formField)
*/
      case FormFieldType.Time:
        return generateTimeField(formField)

      case FormFieldType.DateTime:
        return generateDateTimeField(formField)
    }
  }

  return (
    <>
      <h2>{title}</h2>
      {formFields.map((ufv) => {
        return generateField(ufv)
      })}
    </>
  )
}

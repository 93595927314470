import { Container, ContainerItem } from '../common/container/Container'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import { TrainingApi } from 'modules/training/api/TrainingApi'
import { TrainingService } from '../modules/training/services/TrainingService'
import {
  TRAINING_API_KEY,
  TRAINING_MODULE,
  TRAINING_SERVICE_KEY,
  USER_TRAINING_API_KEY,
  USER_TRAINING_SERVICE_KEY,
} from '../modules/training'
import { ContentProps } from 'modules/training/container'
import { UserTrainingApi } from '../modules/training/api/UserTrainingApi'

let container: Container

function init(p: ContentProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(TRAINING_API_KEY, new ContainerItem({ instance: new TrainingApi() }))
  items.set(USER_TRAINING_API_KEY, new ContainerItem({ instance: new UserTrainingApi() }))

  items.set(
    TRAINING_SERVICE_KEY,
    new ContainerItem({ instance: new TrainingService({ apiKey: TRAINING_API_KEY }) })
  )
  items.set(
    USER_TRAINING_SERVICE_KEY,
    new ContainerItem({ instance: new TrainingService({ apiKey: USER_TRAINING_API_KEY }) })
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getTrainingContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[TRAINING_MODULE],
      },
    })
  }

  return container
}

import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'whatwg-fetch'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import reportWebVitals from './reportWebVitals'
import { prepareModules } from './modules'
import './i18n'
import './config/init-dayjs'
import { caremeTheme } from './common/utils/styles'
import { CssBaseline, ThemeProvider } from '@mui/material'
import DayJsUtils from '@date-io/dayjs'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { SnackbarProvider } from 'notistack'

prepareModules()

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={caremeTheme}>
      <CssBaseline />
      <MuiPickersUtilsProvider utils={DayJsUtils} locale={navigator.language.split('-')[0]}>
        <SnackbarProvider
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          maxSnack={10}>
          <App />
        </SnackbarProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

import { RouteProps } from '../../routes/AppRouter'
import { TrainingsView as TSV } from '../../features/training'
import { useTitleHeaderContext } from '../../common/utils/TitleHeaderContext'

export function TrainingsView(props: RouteProps) {
  const title = props.title || ''
  const { setTitle } = useTitleHeaderContext()

  setTitle(title)
  return <TSV id={props.id} />
}

import React from 'react'
import { RouteProps } from '../../routes/AppRouter'
import { CurrentNavHeader } from '../../components/current-nav-header/CurrentNavHeader'
import { RolePermissions as R } from '../../features/role-permissions'
import { useTranslation } from 'react-i18next'
import { useTitleHeaderContext } from '../../common/utils/TitleHeaderContext'

export function RolePermissions(props: RouteProps) {
  const title = props.title || ''
  const { setTitle } = useTitleHeaderContext()

  setTitle(title)
  const { t } = useTranslation()

  return (
    <>
      <R id={props.id || ''} />
    </>
  )
}

import { createTheme } from '@mui/material'

export const caremeTheme = createTheme({
  palette: {
    primary: {
      main: '#adb84e', // --green
    },
    secondary: {
      main: '#474747', // --greycolor
    },
  },
})

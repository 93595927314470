import { Box, Link } from '@mui/material'
import React from 'react'
import {
  ROUTE_DENTIST_CONFIG,
  ROUTE_NOTIFICATIONS_CONFIG,
  ROUTE_PATIENT_CALENDAR_CONFIG,
  ROUTE_SYMPTOMS_CONFIG,
} from '../../routes/routes-constants'
import notifications from '../../assets/left_menu/notificaciones.svg'
import patientData from '../../assets/left_menu/circulos.svg'
import calendar from '../../assets/left_menu/calendar.svg'
import iconSymptom from '../../assets/left_menu/datos-paciente.svg'
import { useTranslation } from 'react-i18next'
import packageInfo from '../../../package.json'

import style from './Configuration.module.css'
import { getUserContainer } from '../../container/user-module'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { Permission } from 'common/permission'

export type ConfigurationProps = {}
const userContainer = getUserContainer()
const loggedUserService = userContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)

export function Configuration(props: ConfigurationProps): JSX.Element {
  const { t } = useTranslation()

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      flexBasis="100%"
    >
      <Box
        className={style.configurationList}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        {loggedUserService.userCan(Permission.configureNotifications) && (
          <ConfigurationItemLink
            imgSrc={notifications}
            label={t('notificationsConfig')}
            route={ROUTE_NOTIFICATIONS_CONFIG}
            addSeparator={true}
          />
        )}
        {loggedUserService.userCan(Permission.configureSymptoms) && (
          <ConfigurationItemLink
            imgSrc={iconSymptom}
            label={t('symptomsConfig')}
            route={ROUTE_SYMPTOMS_CONFIG}
            addSeparator={true}
          />
        )}
        {loggedUserService.userCan(Permission.configurePatientCalendar) && (
          <ConfigurationItemLink
            imgSrc={calendar}
            label={t('patientCalendarConfig')}
            route={ROUTE_PATIENT_CALENDAR_CONFIG}
            addSeparator={true}
          />
        )}
        {loggedUserService.userCan(Permission.configureDentists) && (
          <ConfigurationItemLink
            imgSrc={patientData}
            label={t('dentistConfig')}
            route={ROUTE_DENTIST_CONFIG}
            addSeparator={true}
          />
        )}
        <h3 className={style.version}>Version {packageInfo.version}</h3>
      </Box>
    </Box>
  )
}

type ConfigurationItemLinkProps = {
  route: string
  label: string
  imgSrc: string
  addSeparator?: boolean
}

function ConfigurationItemLink(props: ConfigurationItemLinkProps): JSX.Element {
  return (
    <>
      <Link className={style.configurationLink} href={props.route} id={props.route}>
        <Box className={style.configurationItem} display="flex" alignItems="center">
          <img className={style.icon} src={props.imgSrc} alt={props.imgSrc} />
          <h3 className={style.configurationItemLabel}>{props.label}</h3>
        </Box>
      </Link>
      {props.addSeparator ? <hr className={style.configurationSeparator} /> : null}
    </>
  )
}

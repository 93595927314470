import React, { useState } from 'react'
import { Search, SearchValue } from './types'
import { Box } from '@mui/material'
import { Input } from '../../features/resource/userProfileStylesMui'

type SearchProps<Q> = {
  readonly search: Search<Q>
}
// const themeStyles = makeStyles(() => ({
//   input: {
//     '&.Mui-focused': {
//       '& fieldset.MuiOutlinedInput-notchedOutline': {
//         borderColor: '#a9ba37 !important',
//       },
//     },
//   },
//   inputUnderline: {
//     '&:after': {
//       borderColor: '#a9ba37 !important',
//     },
//   },
//   selectInput: {
//     '& ': {
//       width: '29rem !important',
//     },

//     '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
//       borderColor: '#a9ba37 !important',
//     },
//   },

//   label: {
//     [`&.${inputLabelClasses.shrink}`]: {
//       color: '#a9ba37',
//       marginTop: '-2%',
//       backgroundColor: 'white',
//       borderColor: '#a9ba37',
//     },
//   },
// }))
export function Searcher<Q extends { [key: string]: any }>(props: SearchProps<Q>) {
  const [values, setValues] = useState<SearchValue<Q>[]>(props.search.searchValues)

  const handleSubmit = () => props.search.handleSearch([...values])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    values.find((sv) => e.target.name == sv.name)!.value = e.target.value
    props.search.handleSearch([...values])
    setValues([...values])
  }

  const handleReset = () => {
    values.forEach((v) => (v.value = ''))
    setValues([...values])
    handleSubmit()
  }
  // const classes = themeStyles()

  return (
    <form onSubmit={handleSubmit}>
      <Box marginBottom="5px">
        <Box display="flex" flexWrap="wrap">
          {values.map((v) => (
            <Box mr={2} width={v.width} key={v.name as string}>
              <Input
                name={v.name as string}
                label={v.label}
                onChange={handleChange}
                //onKeyPress={handleSubmit}
                type={v.type || 'text'}
                value={v.value || ''}
                variant="outlined"
                size="small"
                // InputLabelProps={{ className: classes.label }}
                // InputProps={{ className: classes.input }}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </form>
  )
}

import { useTranslation } from 'react-i18next'
import { navigate } from '@reach/router'
import { Box } from '@mui/material'
import genericStyle from '../../common/utils/generic.module.css'
import styles from './Menu.module.css'
import React from 'react'
import circleIcon from '../../assets/left_menu/circulos-act.svg'
import notifications from '../../assets/left_menu/notificaciones-act.svg'
import circleManageIcon from '../../assets/left_menu/circulos-admin-act.svg'
import patientData from '../../assets/left_menu/datos-paciente-act.svg'
import chat from '../../assets/left_menu/chat-act.svg'
import calendar from '../../assets/left_menu/calendar-act.svg'
import resources from '../../assets/left_menu/recursos-act.svg'
import forms from '../../assets/left_menu/formularios-act.svg'
import library from '../../assets/left_menu/library-act.svg'
import user from '../../assets/superior_menu/user.svg'
import configuration from '../../assets/superior_menu/conf.svg'
import {
  ROUTE_ARTICLES,
  ROUTE_CALENDAR,
  ROUTE_CIRCLES,
  ROUTE_CONFIGURATION,
  ROUTE_MESSENGER,
  ROUTE_NEWS_WALL,
  ROUTE_PATIENT_DATA,
  ROUTE_PATIENT_FORMS,
  ROUTE_RESOURCES,
  ROUTE_USER_PROFILE,
  ROUTE_USERS,
} from '../../routes/routes-constants'

type MobileMenuItem = {
  name: string
  icon: string
  url: string
}

export function MobileMenu() {
  const { t } = useTranslation()
  const mobileMenuItems: MobileMenuItem[] = [
    { name: 'circleOfPatient', icon: circleIcon, url: ROUTE_CIRCLES },
    { name: 'newsWall', icon: notifications, url: ROUTE_NEWS_WALL },
    { name: 'participants', icon: circleManageIcon, url: ROUTE_USERS },
    { name: 'patientData', icon: patientData, url: ROUTE_PATIENT_DATA },
    { name: 'chat', icon: chat, url: ROUTE_MESSENGER },
    { name: 'calendar', icon: calendar, url: ROUTE_CALENDAR },
    { name: 'resources', icon: resources, url: ROUTE_RESOURCES },
    { name: 'patientsForms', icon: forms, url: ROUTE_PATIENT_FORMS },
    { name: 'library', icon: library, url: ROUTE_ARTICLES },
    { name: 'userProfile', icon: user, url: ROUTE_USER_PROFILE },
    { name: 'configuration', icon: configuration, url: ROUTE_CONFIGURATION },
  ]

  const handleOnClick = (url: string) => navigate(url)

  return (
    <Box className={genericStyle.pageContainer}>
      <Box className={styles.centerBlock2}>
        <Box className={styles.centerBlock}>
          {mobileMenuItems.map((item) => {
            return (
              <Box
                className={styles.listBlock}
                onClick={() => handleOnClick(item.url)}
                key={item.name}>
                <div className={styles.iconBlock}>
                  <img src={item.icon} alt={item.name} />
                </div>
                <h2 className={styles.textBlock}>{t(item.name)}</h2>
              </Box>
            )
          })}
        </Box>
      </Box>
    </Box>
  )
}

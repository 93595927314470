import React, { ChangeEvent, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import style from './Searcher.module.css'
import AddIcon from '../../assets/calendar/add.svg'
import { Button, Typography } from '@mui/material'
import { Input } from '../resource/userProfileStylesMui'
import { useIsOpenMenuLeftContext } from '../../common/utils/isOpenMenuLeftContext'

type SearcherProps = {
  handler: (search: string) => void
  reverse: () => void
  handleNewConversation: () => void
}

// const themeStyles = makeStyles(() => ({
//   input: {
//     '&.Mui-focused': {
//       '& fieldset.MuiOutlinedInput-notchedOutline': {
//         borderColor: '#a9ba37 !important',
//       },
//     },
//   },
//   inputUnderline: {
//     '&:after': {
//       borderColor: '#a9ba37 !important',
//     },
//   },
//   selectInput: {
//     '& ': {
//       width: '29rem !important',
//     },

//     '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
//       borderColor: '#a9ba37 !important',
//     },
//   },

//   label: {
//     fontSize: 14,
//     [`&.${inputLabelClasses.shrink}`]: {
//       color: '#a9ba37',
//       marginTop: '-2%',
//       backgroundColor: 'white',
//       borderColor: '#a9ba37',
//     },
//   },
// }))

export function Searcher(props: SearcherProps): JSX.Element {
  const { t } = useTranslation()

  const [search, setSearch] = useState<string>('')
  const [descToAsc, setDescToAsc] = useState<boolean>(false)
  const { openMenuLeft } = useIsOpenMenuLeftContext()

  const handlerChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
    setSearch(event.target.value)

  useEffect(() => {
    props.handler(search)
  }, [search])

  const handleFilterChange = () => {
    props.reverse()
    setDescToAsc(!descToAsc)
  }
  // const classes = themeStyles()
  return (
    <>
      <Box className={style.inputBox}>
        <Typography
          style={{ fontSize: openMenuLeft ? '14px' : '20px' }}
          className={style.filterByChatText}
        >
          {t('filterByConversation')}
        </Typography>
        <Input
          sx={{ width: '207px' }}
          //className={style.inputSearcher}
          label={''}
          variant={'outlined'}
          type="text"
          value={search}
          onChange={handlerChange}
          size={'small'}
          //InputProps={{ className: classes.input }}
          //InputLabelProps={{ className: classes.label }}
        />
      </Box>
      {/*<Box className={style.iconBlock}>
        <FilterListIcon onClick={handleFilterChange} className={containerClassName(descToAsc)} />
      </Box>*/}
      <div className={style.addNewConversationContainer} onClick={props.handleNewConversation}>
        <Button
          startIcon={<img src={AddIcon} alt={'add conversation'} />}
          className={style.addNewConversationButton}
        >
          {t('newConversation2')}
        </Button>
      </div>
    </>
  )
}

// function containerClassName(descToAsc: boolean): string {
//   if (descToAsc) {
//     return style.toAsc + ' ' + style.filterListIcon
//   }
//   return style.toDesc
// }

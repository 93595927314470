export type PatologyProps = {
  id: string | undefined
  name: string
  cron: string
  priority: number | undefined
  min_steps: number
}

export class Patology {
  private readonly _id: string | undefined
  private readonly _name: string
  private readonly _cron: string
  private readonly _priority: number | undefined
  private readonly _min_steps: number

  constructor(p: PatologyProps) {
    this._id = p.id
    this._name = p.name
    this._cron = p.cron
    this._priority = p.priority
    this._min_steps = p.min_steps
  }

  get id(): string | undefined {
    return this._id
  }

  get name(): string {
    return this._name
  }

  get cron(): string {
    return this._cron
  }

  get priority(): number | undefined {
    return this._priority
  }

  get min_steps(): number {
    return this._min_steps
  }
}

export interface PatologyQuery {
  name: string
  cron: string
  priority: number
  min_steps: number
}

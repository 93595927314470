import { TextField } from '@mui/material'
import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

export type TextFieldItemProps = {
  field: string
  value: string
  label: string
  handleChange: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
  rows?: number
  required: boolean
  type?: string
  disabled?: boolean
}
// const styles = withStyles(() => ({
//   input: {
//     '&.Mui-focused': {
//       '& fieldset.MuiOutlinedInput-notchedOutline': {
//         borderColor: '#a9ba37 !important',
//       },
//     },
//   },
//   inputUnderline: {
//     '&:after': {
//       borderColor: '#a9ba37 !important',
//     },
//   },
//   selectInput: {
//     '& ': {
//       width: '29rem !important',
//     },

//     '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
//       borderColor: '#a9ba37 !important',
//     },
//   },

//   label: {
//     [`&.${inputLabelClasses.shrink}`]: {
//       color: '#a9ba37',
//       marginTop: '-0.5%',
//       backgroundColor: 'white',
//       borderColor: '#a9ba37',
//     },
//   },
// }))

export function TextFieldItem(props: TextFieldItemProps) {
  const { t } = useTranslation()
  return (
    <TextField
      id={props.field}
      key={props.field}
      label={t(props.label)}
      name={props.field}
      value={props.value}
      onChange={props.handleChange}
      type={props.type}
      multiline={(props.rows && props.rows > 0) || false}
      rows={props.rows}
      required={props.required}
      disabled={props.disabled}
      fullWidth
      variant="outlined"
      // InputLabelProps={{ className: styles.label }}
      // InputProps={{ className: classes.input }}
    />
  )
}

import { Box, Breadcrumbs, Link, Typography } from '@mui/material'
import styles from './HeaderPolicy.module.css'
import logoCareme from '../../assets/images/caremePrivacy.svg'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import React, { useEffect, useState } from 'react'
import { getUserContainer } from '../../container/user-module'
import { ILoggedUserService } from '../../modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'
import { LoggedUser } from '../../modules/users/models/LoggedUser'
import { useTranslation } from 'react-i18next'
import { NavigateBefore } from '@material-ui/icons'

type HeaderPolicyProps = {
  title: string
}

const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)

export function HeaderPolicy(props: HeaderPolicyProps) {
  const loggedUser = loggedUserService.get()
  const [user, setUser] = useState<LoggedUser | undefined>(loggedUser)
  const { t } = useTranslation()

  useEffect(() => {
    loggedUserService.getObservable().subscribe((lu) => {
      setUser(lu)
    })
  }, [])

  return (
    <>
      {user ? (
        <Box>
          {!user && (
            <Box className={styles.headerLogin}>
              <img src={logoCareme} alt="Logo Careme" className={styles.logo} />
            </Box>
          )}
          <Box className={styles.navigatorLogin}>
            <h1 className={styles.text}>{props.title}</h1>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" style={{ color: '#fff' }} />}
              aria-label="breadcrumb"
            >
              <Link className={styles.linkLogin} underline="none" color="#fff" href="/">
                Careme
              </Link>
              <Typography color="#e5e3e3">{props.title}</Typography>
            </Breadcrumbs>
          </Box>
        </Box>
      ) : (
        <Box>
          <Box className={styles.navigator}>
            <Box display={'flex'} alignItems={'center'}>
              <NavigateBefore fontSize="medium" style={{ color: '#fff', marginRight: 8 }} />
              <Link className={styles.link} underline="none" color="#fff" href="/">
                {t('backOneToResources')}
              </Link>
            </Box>
            {/* <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" style={{ color: '#fff' }} />}
          aria-label="breadcrumb"
        >*/}
            {/*     <Typography color="#e5e3e3">{props.title}</Typography>*/}

            <Box className={styles.header}>
              <img src={logoCareme} alt="Logo Careme" className={styles.logo} />
            </Box>
          </Box>
        </Box>
      )}
    </>
  )
}

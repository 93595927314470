import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { getTrainingContainer } from '../../container/training-module'
import {
  ITRainingService,
  IUserTrainingService,
  TRAINING_SERVICE_KEY,
  USER_TRAINING_SERVICE_KEY,
} from '../../modules/training'
import { CurrentNavHeader } from '../../components/current-nav-header/CurrentNavHeader'
import genericStyle from '../../common/utils/generic.module.css'
import training1 from '../../assets/training/training1.e56745ed.png'
import training2 from '../../assets/training/training2.f64bb88e.png'
import training3 from '../../assets/training/training3.4a9558d5.png'
import styles from './viewStyle.module.css'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import {
  emptyUserTrainingDTO,
  fromModel,
  UserTrainingDTO,
} from '../../modules/training/models/UserTrainingDTO'
import { emptyTrainingDTO, TrainingDTO } from '../../modules/training/models/TrainingDTO'
import { navigate } from '@reach/router'
import { ROUTE_TRAINING, ROUTE_TRAININGS } from '../../routes/routes-constants'
import { User } from '../../modules/users/models/User'
import { getUserContainer } from '../../container/user-module'
import { UserService } from '../../modules/users/services/UserService'
import { LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from '../../modules/users'
import { ILoggedUserService } from '../../modules/users/services/LoggedUserService'

type TrainingView = {
  id?: string
}

const userContainer = getUserContainer()
const userService = userContainer.get<UserService>(USER_SERVICE_KEY)
const loggedUserService = userContainer.get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
const trainingService = getTrainingContainer().get<ITRainingService>(TRAINING_SERVICE_KEY)
const userTrainingService = getTrainingContainer().get<IUserTrainingService>(
  USER_TRAINING_SERVICE_KEY
)

export function ViewMedical(props: TrainingView) {
  const { t } = useTranslation()
  const loggedUser = loggedUserService.get()
  const [user, setUser] = useState<User>()
  const [training, setTraining] = useState<TrainingDTO>(emptyTrainingDTO())
  const [userTraining, setUserTraining] = useState<UserTrainingDTO>(emptyUserTrainingDTO())
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    if (!loggedUser?.id) return
    userService.getByID(loggedUser?.id).subscribe((res) => {
      res && setUser(res)
      setIsLoading(false)
    })
  }, [])

  useEffect(() => {
    if (!isLoading || !props?.id) {
      return
    }

    trainingService.getByID(props?.id).subscribe((res) => {
      res && setTraining(res)
    })
  }, [isLoading])

  const selectImage = (image: string) => {
    switch (image) {
      case 'training1':
        return training1
      case 'training2':
        return training2
      case 'training3':
        return training3
    }
  }

  const handlerCompleted = () => {
    const newUserTraining = Object.assign({ ...userTraining }, { completed: true })

    if (!props.id) return
    userTrainingService.update(newUserTraining).subscribe((res) => setIsLoading(!isLoading))
  }

  const goBack = () => {
    navigate(user?.isPatient ? ROUTE_TRAINING : ROUTE_TRAININGS)
  }

  return (
    <Box>
      {!isLoading && training ? (
        <>
          <CurrentNavHeader title={training?.title} />
          <Box className={genericStyle.pageContainer}>
            <Box className={styles.trainingContainer}>
              <Box>
                <img className={styles.trainingImage} src={selectImage(training.image)} />
              </Box>
              <Box className={styles.description}>
                <Box>
                  <p className={styles.descriptionTitle}>{`${t('description')}: `}</p>
                  <p className={styles.textDescription}>{training.description}</p>
                </Box>
                <Box className={styles.buttons}>
                  <Box style={{ margin: 8 }}>
                    <AppButton
                      label={t('back')}
                      theme={ButtonTheme.NewPrimary}
                      handler={goBack}
                      type={'button'}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        ''
      )}
    </Box>
  )
}

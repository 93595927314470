import { FormField } from './FormField'
import { Form } from './Form'
import { FormFieldType } from '../enums/FormFieldType'
import { v4 as uuidv4 } from 'uuid'

export interface FormDTO {
  id: string | undefined
  title: string
  description: string
  formFields: FormFieldDTO[]
  repeating: string
}

export function emptyFormDTO(): FormDTO {
  return {
    id: uuidv4(),
    title: '',
    description: '',
    formFields: [],
    repeating: '',
  }
}

export function fromModel(f: Form): FormDTO {
  return {
    id: f.id,
    title: f.title,
    description: f.description,
    formFields: f.formFields.map((ff) => fromModelFormField(ff, f.id)),
    repeating: f.repeating,
  }
}

export function toModel(d: FormDTO): Form {
  return new Form({
    description: d.description,
    formFields: d.formFields.map((ff) => toModelFormField(ff)),
    id: d.id,
    repeating: d.repeating,
    title: d.title,
  })
}

export interface FormFieldDTO {
  id: string
  formID: string | undefined
  title: string
  type: FormFieldType
  required: boolean
  order: number
  optionValues: string[]
  correctValues: string | Function | string[] | undefined
}

export function emptyFormFieldDTO(order: number): FormFieldDTO {
  return {
    id: uuidv4(),
    formID: undefined,
    title: '',
    type: FormFieldType.TextArea,
    required: false,
    order,
    optionValues: [],
    correctValues: [],
  }
}

export function fromModelFormField(ff: FormField, formID: string | undefined): FormFieldDTO {
  return {
    id: ff.id,
    formID,
    title: ff.title,
    type: ff.type,
    required: ff.required,
    order: ff.order,
    optionValues: ff.getOptionsValues(),
    correctValues: ff.getCorrectValues(),
  }
}

export function toModelFormField(d: FormFieldDTO): FormField {
  return new FormField({
    correctValues: d.correctValues,
    formID: d.formID,
    id: d.id,
    optionValues: d.optionValues[0].split(','),
    order: d.order,
    required: d.required,
    title: d.title,
    type: d.type,
  })
  //return new FormField(d)
}

export const ROUTE_HOME = `/`
export const ROUTE_CREATE = `create`
export const ROUTE_CIRCLES = `/`
export const ROUTE_USERS = `/users`
export const ROUTE_PATIENTS = `/patients`
export const ROUTE_USER_PROFILE = `/user-profile`
export const ROUTE_NEWS_WALL = `/newsWall`
export const ROUTE_NOTIFICATIONS = `/newsWall`
export const ROUTE_FORM_GENERATOR = `/form-generator`
export const ROUTE_FORM_GENERATOR_FORM = `/form-generator/form`
export const ROUTE_FORM_GENERATOR_FORM_EDIT = `/form-generator/form/:id`
export const ROUTE_FORM_GENERATOR_FORM_CREATE = `/form-generator/form/create`
export const ROUTE_FORM_GENERATOR_FORM_REMOVE = `/form-generator/remove`
export const ROUTE_FORM_GENERATOR_FORM_RESULTS = `/form-generator/form/:id/results`
export const ROUTE_FORM_GENERATOR_USERS = `/form-generator/:id/users`
export const ROUTE_FORM_GENERATOR_ASSIGNTO = `/form-generator/:id/assignTo`
export const ROUTE_PATIENT_FORMS = `/forms`
export const ROUTE_PATIENT_FORMS_VIEW = `/forms/:id`
export const ROUTE_MESSENGER = `/messenger`
export const ROUTE_MESSENGER_ID = `/messenger/:id`
export const ROUTE_CALENDAR = `/calendar`
export const ROUTE_CALENDAR_EXERCISES = `/calendar/exercises`
export const ROUTE_CALENDAR_EXERCISES_ID = `/calendar/exercises/:id`
export const ROUTE_CALENDAR_ID = `/calendar/:id`
export const ROUTE_CALENDAR_FORM = `/calendar/form`
export const ROUTE_CALENDAR_FORM_EDIT = `/calendar/form/:id`
export const ROUTE_ARTICLES = `/articles`
export const ROUTE_ARTICLE = `/articles/:id`
export const ROUTE_ARTICLES_FORM = `/articles/form`
export const ROUTE_ARTICLES_FORM_EDIT = `/articles/form/:id`
export const ROUTE_CONFIGURATION = `/configuration`
export const ROUTE_RESOURCES = `/resources`
export const ROUTE_RESOURCES_ID = `/resources/:id`
export const ROUTE_RESOURCES_SHARED_WITH_ME = `/resources/shared`
export const ROUTE_RESOURCES_SHARED_WITH_ME_ID = `/resources/shared/:id`
export const ROUTE_RESOURCES_FORM = `/resources/form`
export const ROUTE_RESOURCES_FORM_EDIT = `/resources/form/:id`
export const ROUTE_STATISTICS = `/statistics`
export const ROUTE_STATISTICS_ID = `/statistics/:id`
export const ROUTE_NOTIFICATIONS_CONFIG = `/notifications-config`
export const ROUTE_SYMPTOMS_CONFIG = `/symptoms-config`
export const ROUTE_DENTIST_CONFIG = `/dentist-config`
export const ROUTE_ROLE_PERMISSIONS = `/role-permissions`
export const ROUTE_PATIENT_DATA = `/patient-data`
export const ROUTE_PATIENT_CALENDAR_CONFIG = `/patient-calendar-config`
export const ROUTE_MOBILE_MENU = `/mobile-menu`
export const ROUTE_USERS_CREATE = `/users/createParticipant`
export const ROUTE_USERS_CREATE_ID = `/users/createParticipant/:id`
export const ROUTE_LOGIN = '/login'
export const ROUTE_TRAINING = '/training'
export const ROUTE_DASHBOARD_TRAINING = '/users/dashboard/trainings'
export const ROUTE_DASHBOARD_TRAINING_ID = '/users/dashboard/trainings/:id'
export const ROUTE_TRAININGS = '/trainings'
export const ROUTE_TRAININGS_CREATE = '/trainings/createTraining'
export const ROUTE_TRAINING_ID = '/training/:id'
export const ROUTE_TRAININGS_ID = '/trainings/:id'
export const ROUTE_DASHBOARD = '/users/dashboard'
export const ROUTE_DASHBOARD_ID = '/users/dashboard/:id'
export const ROUTE_TREATMENTS = '/users/dashboard/treatments'
export const ROUTE_TREATMENTS_ID = '/users/dashboard/treatments/:id'
export const ROUTE_USER_FORMS = '/users/forms/'
export const ROUTE_PRIVACY_POLICY = '/policy'
export const ROUTE_COOKIES_POLICY = '/cookies'

import React, { ChangeEvent, useEffect, useState } from 'react'
import { FormFieldType, formFieldTypes } from '../../modules/forms/enums/FormFieldType'
import {
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import styles from './EditorField.module.css'
import { isSpecialType } from '../../common/utils/enums'
import { FormFieldDTO } from '../../modules/forms/models/FormDTO'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'

type FormEditorFieldProps = {
  handleChange: (item: FormFieldDTO) => void
  handleRemove: (id: string) => void
  formField: FormFieldDTO
}

const fieldTypes = formFieldTypes()

export const EditorFieldsItem: React.FC<FormEditorFieldProps> = (props) => {
  const { t } = useTranslation()
  const [formField, setFormField] = useState<FormFieldDTO>(props.formField)

  const [currentOption, setCurrentOption] = useState<string>('')

  // TODO implement in the future
  // TODO validate if there are optionValues, correctValues must be subset of those
  //  const [correctValues, setCorrectValues] = useState([])

  const handleType = (e: SelectChangeEvent<FormFieldType>, name: string) =>
    setFormField(Object.assign({ ...formField }, { [name]: +(e.target.value as number) }))

  const handleOrder = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, name: string) =>
    setFormField(Object.assign({ ...formField }, { [name]: +parseInt(e.target.value) }))

  const handleInput = (e: ChangeEvent<HTMLInputElement>) =>
    setFormField(Object.assign({ ...formField }, { [e.target.name]: e.target.value }))

  const handleRequired = (_: ChangeEvent<HTMLInputElement>) =>
    setFormField(Object.assign({ ...formField }, { required: !formField.required }))

  const handleOption = (option: string) =>
    setFormField(
      Object.assign(
        { ...formField },
        {
          optionValues: formField.optionValues.filter((o) => o !== option),
        }
      )
    )

  useEffect(() => {
    props.handleChange(formField)
  }, [formField])

  const enterHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      if (
        currentOption.length > 0 &&
        !formField.optionValues.includes(currentOption.toLowerCase())
      ) {
        setFormField(
          Object.assign(
            { ...formField },
            { optionValues: [...formField.optionValues, currentOption] }
          )
        )
      }
      setCurrentOption('')
    }
  }

  const deleteFormField = () => props.handleRemove(formField.id)

  return (
    <Box className={styles.formEditorField}>
      <Box mb={3} display="flex">
        <b className={styles.label}>{t('order')}</b>
        <TextField
          key="order"
          variant="outlined"
          inputProps={{ min: '1', max: '100' }}
          type="number"
          name="order"
          value={formField.order}
          onChange={(e) => handleOrder(e, 'order')}
        />
        <b className={styles.label + ' ' + styles.sameLine}>{t('title')}</b>
        <Box className={styles.box}>
          <TextField
            key="title"
            variant="outlined"
            name="title"
            value={formField.title}
            onChange={handleInput}
            fullWidth
            required
          />
        </Box>
      </Box>
      <Box display="flex">
        <b className={styles.label}>{t('type')}</b>
        <Box className={styles.typeBox}>
          <Select
            key={'type'}
            id={'type'}
            variant={'outlined'}
            value={formField.type}
            onChange={(e) => handleType(e, 'type')}
            className={styles.type}
            required
          >
            {Object.keys(fieldTypes).map((k, i) => (
              <MenuItem key={'form-editor-field_menuItem_' + i} value={k}>
                {fieldTypes[k as unknown as FormFieldType]}
              </MenuItem>
            ))}
          </Select>
        </Box>
        {isSpecialType(formField.type) && (
          <Box className={styles.optionBox}>
            <TextField
              className={styles.input}
              key="currentOption"
              variant="outlined"
              placeholder={t('optionValues') + ' ⏎'}
              name="currentOption"
              value={currentOption}
              onChange={(e) => setCurrentOption(e.target.value)}
              onKeyPress={enterHandler}
            />
            {Array.isArray(formField.optionValues) ? (
              <ul className={styles.list}>
                {formField.optionValues
                  .filter((o) => o !== '')
                  .map((o, i) => (
                    <li key={i} className={styles.listItem}>
                      <Chip
                        label={o}
                        onDelete={() => handleOption(o)}
                        onClick={() => handleOption(o)}
                      />
                    </li>
                  ))}
              </ul>
            ) : (
              <></>
            )}
          </Box>
        )}
      </Box>
      <Box display="flex" justifyContent="space-between">
        <FormControlLabel
          className={styles.requiredLabel}
          label={t('required')}
          labelPlacement={'start'}
          control={
            <Checkbox
              key="required"
              name="required"
              value={formField.required}
              checked={formField.required}
              onChange={handleRequired}
            />
          }
        />
        <AppButton
          theme={ButtonTheme.NewSecondary}
          type={'button'}
          label={t('delete')}
          handler={deleteFormField}
        />
      </Box>
    </Box>
  )
}

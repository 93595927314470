import {
  Box,
  CircularProgress,
  LinearProgress,
} from '@mui/material'
import React from 'react'

export type LoadingSpinnerProps = {
  className?: string
  progress?: number
}

// const customThemeSpinner = createMuiTheme({
//   palette: {
//     primary: {
//       main: '#a9ba37 ',
//       contrastText: '#ffffff !important', // --color-dark-blue
//     },
//   },
// })

// TODO Convert this loader into modal
export function LoadingSpinner(props: LoadingSpinnerProps): JSX.Element {
  return (
    <Box className={props.className} overflow="auto" flexGrow="1" key={'box6'}>
      <CircularProgress disableShrink size="7rem" />
    </Box>
  )
}

export function LoadingFile(props: LoadingSpinnerProps): JSX.Element {
  return (
    <Box className={props.className} flexGrow="2" key={'box6'}>
      <LinearProgress value={props.progress} />
    </Box>
  )
}

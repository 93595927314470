import React, { useEffect, useState } from 'react'
import { getFormContainer } from '../../container/form-module'
import { IUserFormService, USER_FORM_SERVICE_KEY } from '../../modules/forms'
import { Box, Checkbox, TextField } from '@mui/material'
import { navigate } from '@reach/router'
import { ROUTE_FORM_GENERATOR } from '../../routes/routes-constants'
import style from './Editor.module.css'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Query, QueryParam } from '../../common/api/Query'
import { UserQuery } from '../../modules/users/models/User'
import { getUserContainer } from '../../container/user-module'
import { IUserService, LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from '../../modules/users'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { useTranslation } from 'react-i18next'
import genericStyle from '../../common/utils/generic.module.css'
import { Header } from '../../components/header/Header'
import { CronItemMockup } from '../../components/form-card/CronItemMockup'
import { Cron, cronString } from '../../modules/forms/enums/Cron'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { emptyUserFormDTO, toModel } from '../../modules/forms/models/UserFormDTO'
import { useSnackbar } from 'notistack'

type AutocompleteUserInterface = {
  key: string
  value: string
}

type EditorProps = {
  id?: string
}

const formContainer = getFormContainer()
const userFormService = formContainer.get<IUserFormService>(USER_FORM_SERVICE_KEY)
const userService = getUserContainer().get<IUserService>(USER_SERVICE_KEY)
const loggedUserContainer = getUserContainer()
const loggedUserService = loggedUserContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)

export const AssignTo = (props: EditorProps): JSX.Element => {
  const { t } = useTranslation()
  const cronTypes = cronString()
  const [possibleParticipants, setPossibleParticipants] = useState<
    Map<string, AutocompleteUserInterface>
  >(new Map())
  const [selectedUser, setSelectedUser] = useState<AutocompleteUserInterface>()
  const [isRecurrence, setIsRecurrence] = useState<boolean>(false)
  const [cron, setCron] = useState<string>(cronTypes[Cron.Empty])
  const [startDate, setStartDate] = useState<Date>(new Date())
  const [finishDate, setFinishDate] = useState<Date>(new Date())
  const { enqueueSnackbar } = useSnackbar()

  const goBack = () => navigate(ROUTE_FORM_GENERATOR)

  const handleChangeRecurrence = (s: string, startDate: Date, finishDate: Date) => {
    setCron(s)
    setStartDate(startDate)
    setFinishDate(finishDate)
  }

  useEffect(() => {
    userService
      .getFilteredList(
        new Query({
          query: [new QueryParam<UserQuery>('assignedID', loggedUserService.get()?.id || '')],
        })
      )
      .subscribe((res) => {
        const newMap = new Map()
        res.items.forEach((u, i) => {
          newMap.set(u.id, { key: u.id, value: `${u.firstName} ${u.lastName}` })
        })
        setPossibleParticipants(newMap)
      })
  }, [])

  const saveAssignation = () => {
    if (!selectedUser || !selectedUser.key) {
      enqueueSnackbar('Error', { variant: 'error' })
    } else {
      let userForm = emptyUserFormDTO(selectedUser?.key || '', '')
      userForm.formID = props.id || ''
      const toModelUserForm = toModel(userForm)
      userFormService.add(toModelUserForm, cron, startDate, finishDate).subscribe((_) => {
        enqueueSnackbar('Success', { variant: 'success' })
        goBack()
      })
    }
  }

  return (
    <Box className={style.container}>
      <Box style={{ marginBottom: '40px' }}>
        <Header label={t('formAssignation')} />
      </Box>

      <Box display={'flex'} flexDirection={'column'} justifyContent={'space-between'}>
        <p className={style.label}>{t('participant')}</p>
        <Autocomplete
          className={style.autocomplete}
          style={{ width: '100%' }}
          key={props.id}
          id={`${props.id}-standard`}
          value={selectedUser}
          options={Array.from(possibleParticipants.values()) as AutocompleteUserInterface[]}
          getOptionLabel={(opt) => t(opt.value)}
          onChange={(_, v) => {
            v && setSelectedUser(v)
          }}
          renderInput={(params) => <TextField {...params} variant={'outlined'} />}
          noOptionsText={t('noElementsAvailable')}
          size={'small'}
        />
      </Box>

      <Box mb={3} className={style.box} display="flex">
        <b className={style.descriptionLabel} style={{ marginTop: '1.2%' }}>
          {t('recurrence')}
        </b>
        <Box display="flex" className={style.checkBox}>
          <Checkbox
            checked={isRecurrence}
            name={'recurrence'}
            onChange={() => setIsRecurrence(!isRecurrence)}
          />
        </Box>
      </Box>
      {isRecurrence && (
        <Box mb={3}>
          <CronItemMockup handleChange={handleChangeRecurrence} />
        </Box>
      )}

      <Box display="flex" justifyContent="flex-end">
        <div className={style.separator} />
        <AppButton
          theme={ButtonTheme.NewSecondary}
          type={'button'}
          label={t('cancel')}
          handler={goBack}
        />
        <div className={style.separator} />
        <AppButton
          theme={ButtonTheme.NewPrimary}
          type={'submit'}
          label={t('save')}
          handler={saveAssignation}
        />
      </Box>
    </Box>
  )
}

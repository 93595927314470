export type TreatmentProps = {
  id: string | undefined
  name: string
  startDate: Date
  endDate: Date | undefined
  duration: number
  frequency: number
  dose: string
  reason: string | undefined
  creatorID: string
  userID: string
}

export class Treatment {
  private readonly _id: string | undefined
  private readonly _name: string
  private readonly _startDate: Date
  private readonly _endDate: Date | undefined
  private readonly _duration: number
  private readonly _frequency: number
  private readonly _dose: string
  private readonly _creatorID: string
  private readonly _userID: string

  private _reason: string | undefined

  constructor(p: TreatmentProps) {
    this._id = p.id
    this._name = p.name
    this._startDate = p.startDate
    this._endDate = p.endDate
    this._duration = p.duration
    this._frequency = p.frequency
    this._dose = p.dose
    this._reason = p.reason
    this._creatorID = p.creatorID
    this._userID = p.userID
  }

  get id(): string | undefined {
    return this._id
  }

  get name(): string {
    return this._name
  }

  get startDate(): Date {
    return this._startDate
  }

  get endDate(): Date | undefined {
    return this._endDate
  }

  get duration(): number {
    return this._duration
  }

  get frequency(): number {
    return this._frequency
  }

  get dose(): string {
    return this._dose
  }

  get reason(): string | undefined {
    return this._reason
  }

  set reason(value: string | undefined) {
    this._reason = value
  }

  get creatorID(): string {
    return this._creatorID
  }

  get userID(): string {
    return this._userID
  }
}

export interface TreatmentQuery {
  name: string
  user: string
  userID: string
}

import { RouteProps } from '../../routes/AppRouter'
import { Table as FGU } from '../../features/form-generator/Table'
import { useTitleHeaderContext } from '../../common/utils/TitleHeaderContext'

type FormGeneratorUserProps = {
  selectedCircle?: string
} & RouteProps

export function FormGeneratorUsers(props: RouteProps) {
  const title = props.title || ''
  const { setTitle } = useTitleHeaderContext()

  setTitle(title)
  const state: FormGeneratorUserProps = props.location?.state as FormGeneratorUserProps

  return (
    <>
      <FGU></FGU>
    </>
  )
}

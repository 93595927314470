import { UserForm } from '../models/UserForm'
import { Container, IInit } from '../../../common/container/Container'
import { FormContainerConfig } from '../container'
import { Query } from '../../../common/api/Query'
import { prepareURL } from '../../../common/api/http-helpers'
import { catchError, map } from 'rxjs/operators'
import { Observable, of } from 'rxjs'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { emptyList, ItemList } from '../../../common/models/ItemList'
import {
  fromModel,
  toModel,
  toModelValue,
  UserFormDTO,
  UserFormValueDTO,
} from '../models/UserFormDTO'
import { UserFormValue } from '../models/UserFormValue'
import { STATUS_SERVICE_KEY } from '../../../container/app'

export interface IUserFormApi extends IInit {
  getByID(id: string): Observable<UserForm | undefined>

  getFilteredList(q: Query<UserForm>): Observable<ItemList<UserForm>>

  add(
    e: UserForm,
    cron: string,
    startDate: Date,
    finishDate: Date
  ): Observable<UserForm | undefined>

  update(e: UserFormDTO): Observable<UserForm | undefined>

  updateValue(e: UserFormValueDTO): Observable<UserFormValue | undefined>

  delete(id: string): Observable<boolean>
}

export class UserFormApi implements IUserFormApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as FormContainerConfig).moduleFullUrl + '/user-form'
  }

  getByID(id: string): Observable<UserForm | undefined> {
    return this._httpClient
      .get<UserForm>({
        url: `${this._url}/${id}`,
      })
      .pipe(
        map<UserFormDTO, UserForm>((d) => toModel(d)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  add(
    e: UserForm,
    cron: string,
    startDate: Date,
    finishDate: Date
  ): Observable<UserForm | undefined> {
    return this._httpClient
      .post<UserForm>({
        url: this._url,
        body: { ...fromModel(e), cron: cron, startDate: startDate, finishDate: finishDate },
      })
      .pipe(
        map<UserFormDTO, UserForm>((d) => toModel(d)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: `${this._url}/${id}` }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getFilteredList(q: Query<UserForm>): Observable<ItemList<UserForm>> {
    return this._httpClient
      .get<ItemList<UserForm>>({ url: prepareURL(this._url, q) })
      .pipe(
        map<ItemList<UserFormDTO>, ItemList<UserForm>>((dto) => {
          const itemList = emptyList<UserForm>()
          itemList.count = dto.count
          itemList.items = dto.items.map((d) => toModel(d))
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<UserForm>())
        })
      )
  }

  update(e: UserFormDTO): Observable<UserForm | undefined> {
    return this._httpClient
      .put<UserForm>({ url: this._url, body: e })
      .pipe(
        map<UserFormDTO, UserForm>((d) => {
          this._statusService.sendStatus({ variant: 'success' })
          return toModel(d)
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  updateValue(e: UserFormValueDTO): Observable<UserFormValue | undefined> {
    return this._httpClient
      .put<UserFormValue>({ url: this._url + '-value', body: e })
      .pipe(
        map<UserFormValueDTO, UserFormValue>((d) => {
          return toModelValue(d)
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }
}

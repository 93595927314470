import { RouteProps } from '../../routes/AppRouter'
import React from 'react'
import { CalendarForm as CF } from '../../features/calendar'
import { ROUTE_CREATE } from '../../routes/routes-constants'
import { useTitleHeaderContext } from '../../common/utils/TitleHeaderContext'
import { Box } from '@mui/material'
import logoCareme from '../../assets/caremeLogoMain.svg'
import style from '../layout/Main.module.css'

type CalendarFormProps = {
  date?: Date
  currentUrl?: string
} & RouteProps

export function CalendarForm(props: CalendarFormProps) {
  const title = props.title || ''
  const { setTitle } = useTitleHeaderContext()

  setTitle(title)
  const state: CalendarFormProps = props.location?.state as CalendarFormProps

  return (
    <>
      <CF
        id={props.id === ROUTE_CREATE ? undefined : props.id}
        date={state.date}
        currentUrl={state.currentUrl}
      />
      <Box display={'flex'} justifyContent={'flex-end'}>
        <img src={logoCareme} alt="Logo Careme" className={style.logo} />
      </Box>
    </>
  )
}

import React, { useState, useEffect } from 'react'
import Friend from './Friend'
import Chat from './Chat.js'

const Friends = ({ listOpen }) => {
  const [chatOpen, setChatOpen] = useState(listOpen)
  const [user, setUser] = useState([])

  const friend = [
    {
      id: 1,
      photo: 'avatar-1.jpg',
      name: 'Josephin Doe',
      new: 3,
      status: 1,
      time: 'typing',
    },
    {
      id: 2,
      photo: 'avatar-2.jpg',
      name: 'Lary Doe',
      new: 1,
      status: 1,
      time: 'online',
    },
    {
      id: 3,
      photo: 'avatar-3.jpg',
      name: 'Alice',
      status: 1,
      time: 'online',
    },
    {
      id: 4,
      photo: 'avatar-1.jpg',
      name: 'Alia',
      status: 0,
      new: 1,
      time: '10 min ago',
    },
    {
      id: 5,
      photo: 'avatar-4.jpg',
      name: 'Suzen',
      status: 0,
      time: '15 min ago',
    },
    {
      id: 6,
      photo: 'avatar-1.jpg',
      name: 'Josephin Doe',
      new: 3,
      status: 1,
      time: 'typing',
    },
    {
      id: 7,
      photo: 'avatar-2.jpg',
      name: 'Lary Doe',
      new: 1,
      status: 1,
      time: 'online',
    },
    {
      id: 8,
      photo: 'avatar-3.jpg',
      name: 'Alice',
      status: 1,
      time: 'online',
    },
    {
      id: 9,
      photo: 'avatar-1.jpg',
      name: 'Alia',
      status: 0,
      new: 1,
      time: '10 min ago',
    },
    {
      id: 10,
      photo: 'avatar-4.jpg',
      name: 'Suzen',
      status: 0,
      time: '15 min ago',
    },
    {
      id: 11,
      photo: 'avatar-1.jpg',
      name: 'Josephin Doe',
      new: 3,
      status: 1,
      time: 'typing',
    },
    {
      id: 12,
      photo: 'avatar-2.jpg',
      name: 'Lary Doe',
      new: 1,
      status: 1,
      time: 'online',
    },
    {
      id: 13,
      photo: 'avatar-3.jpg',
      name: 'Alice',
      status: 1,
      time: 'online',
    },
    {
      id: 14,
      photo: 'avatar-1.jpg',
      name: 'Alia',
      status: 0,
      new: 1,
      time: '10 min ago',
    },
    {
      id: 15,
      photo: 'avatar-4.jpg',
      name: 'Suzen',
      status: 0,
      time: '15 min ago',
    },
    {
      id: 16,
      photo: 'avatar-1.jpg',
      name: 'Josephin Doe',
      new: 3,
      status: 1,
      time: 'typing',
    },
    {
      id: 17,
      photo: 'avatar-2.jpg',
      name: 'Lary Doe',
      new: 1,
      status: 1,
      time: 'online',
    },
    {
      id: 18,
      photo: 'avatar-3.jpg',
      name: 'Alice',
      status: 1,
      time: 'online',
    },
    {
      id: 19,
      photo: 'avatar-1.jpg',
      name: 'Alia',
      status: 0,
      new: 1,
      time: '10 min ago',
    },
    {
      id: 20,
      photo: 'avatar-4.jpg',
      name: 'Suzen',
      status: 0,
      time: '15 min ago',
    },
    {
      id: 21,
      photo: 'avatar-1.jpg',
      name: 'Josephin Doe',
      new: 3,
      status: 1,
      time: 'typing',
    },
    {
      id: 22,
      photo: 'avatar-2.jpg',
      name: 'Lary Doe',
      new: 1,
      status: 1,
      time: 'online',
    },
    {
      id: 23,
      photo: 'avatar-3.jpg',
      name: 'Alice',
      status: 1,
      time: 'online',
    },
    {
      id: 24,
      photo: 'avatar-1.jpg',
      name: 'Alia',
      status: 0,
      new: 1,
      time: '10 min ago',
    },
    {
      id: 25,
      photo: 'avatar-4.jpg',
      name: 'Suzen',
      status: 0,
      time: '15 min ago',
    },
  ]

  useEffect(() => {
    setChatOpen(false)
  }, [listOpen])

  const friendList = friend.map((f) => {
    return (
      <Friend
        key={f.id}
        data={f}
        activeId={user.id}
        clicked={() => {
          setChatOpen(true)
          setUser(f)
        }}
      />
    )
  })

  return (
    <React.Fragment>
      {friendList}
      <Chat
        user={user}
        chatOpen={chatOpen}
        listOpen={listOpen}
        closed={() => {
          setChatOpen(false)
          setUser([])
        }}
      />
    </React.Fragment>
  )
}

export default Friends

import { Box, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import React, { FormEvent, useRef, useState } from 'react'
import style from './modalUpdatePhoto.module.css'
import { getUserContainer } from '../../container/user-module'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users/container'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import close from '../../assets/articles/delete.svg'
import { Header } from '../../components/header/Header'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { dataToBase64 } from '../../common/utils/file'
import { emptyFileDTO, FileDTO } from '../../modules/files/models/FileDTO'
import { getFileContainer } from '../../container/file-module'
import { FileService } from '../../modules/files/services/FileService'
import { FILE_SERVICE_KEY } from '../../modules/files'
import { useProfilePhotoGlobalContext } from '../../common/utils/ProfilePhotoContext'
import { Input } from '../../pages/user-profile/userProfileStylesMui'
import { Typography } from '@mui/material'

type NewUpdatePhotoProps = {
  handleClose: () => void
}

const filesContainer = getFileContainer()
const loggedUserService = getUserContainer().get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
const fileService = filesContainer.get<FileService>(FILE_SERVICE_KEY)

export const ModalUpdatePhoto = (props: NewUpdatePhotoProps): JSX.Element => {
  const { t } = useTranslation()
  const loggedUser = loggedUserService.get()
  const [resource, setResource] = useState<FileDTO>(emptyFileDTO(loggedUser?.id))
  const { setProfilePhoto } = useProfilePhotoGlobalContext()
  const innerWidth = window.innerWidth
  const [fileName, setFileName] = useState<string>('')

  const handleFileInput = async (event: any) => {
    const files = event.target.files
    const fileUploaded = files[0]
    setFileName(fileUploaded.name)
    if (!files?.length) {
      return
    }
    const data = await dataToBase64(files[0])
    const file = files[0]

    const fileDTO = emptyFileDTO('')
    fileDTO.name = file.name
    fileDTO.extension = file.name.substring(file.name.lastIndexOf('.') + 1)
    fileDTO.ownerID = loggedUser ? loggedUser.id : ''
    fileDTO.mimeType = file.mimeType
    fileDTO.data = data
    fileDTO.size = data.length

    setResource(fileDTO)
  }

  const savePhoto = (e: FormEvent<HTMLFormElement>) => {
    setProfilePhoto(resource)
    fileService.addProfilePhoto(resource).subscribe()
    props.handleClose()
  }

  const hiddenFileInput = useRef<HTMLInputElement | null>(null)

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (e: any) => {
    e.preventDefault()
    hiddenFileInput.current?.click()
  }

  return (
    <Box className={style.newConversationContainer}>
      <Header label={t('editPhoto')} icon={close} onClick={props.handleClose} />

      {/*<Input
        fullWidth
        variant={'outlined'}
        id="pdfFile"
        onChange={handleFileInput}
        type={'file'}
        inputProps={{ accept: '.png, .jpeg, .jpg' }}
        //className={style.input}
      />*/}

      <Box className={style.buttonFileContainer}>
        <button
          style={{
            fontSize: innerWidth < 1000 ? '10px' : '15px',
            width: innerWidth < 1000 ? 'auto' : '',
          }}
          className={style.buttonFile}
          onClick={handleClick}
        >
          {t('selectFile')}
        </button>
        <input
          onChange={handleFileInput}
          style={{ display: 'none' }}
          id="pdfFile"
          type={'file'}
          ref={hiddenFileInput}
        />
        <Typography
          sx={{ fontSize: innerWidth < 1000 ? '12px' : '18px' }}
          className={style.fileName}
        >
          {fileName ? fileName : t('nothingSelected')}
        </Typography>
      </Box>

      <form onSubmit={savePhoto}>
        <Box display={'flex'} justifyContent={'center'} />
        <Box display={'flex'} justifyContent={'center'} width={'100%'} style={{ marginTop: '8%' }}>
          <Box mr={4}>
            <AppButton
              theme={ButtonTheme.NewSecondary}
              type={'button'}
              label={t('close')}
              handler={props.handleClose}
            />
          </Box>
          <AppButton
            theme={ButtonTheme.NewPrimary}
            type={'submit'}
            label={t('save')}
            handler={() => {}}
          />
        </Box>
      </form>
    </Box>
  )
}
